import axios from "axios";
import { authHeader, check419 } from "../helpers";

export const EquipmentUsedReportService = {
  equipmentUsedReportApi,
  equipmentUsedReportDownloadApi,
  getItemUsedExport,
  getAllItemUsedExport
};

function getAllItemUsedExport(search) {
  return axios
    .post(
      process.env.REACT_APP_APIURL +
        "reps/booking/get-all-item-used-bookings-export",  
 
      JSON.stringify(search),
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("reps item used report Response", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 419) {
        check419();
      } else {
        console.error("Error in item used report:", error);
        throw error;
      }
    });
}

function getItemUsedExport(search) {
  return axios
    .post(
      process.env.REACT_APP_APIURL +
        "reps/booking/get-booking-for-dashboard-export",
      JSON.stringify(search),
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("reps item used report Response", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 419) {
        check419();
      } else {
        console.error("Error in item used report:", error);
        throw error;
      }
    });
}

function equipmentUsedReportApi(search) {
  return axios
    .post(
      process.env.REACT_APP_APIURL + "products/equipmentUsedReportApi",
      JSON.stringify(search),
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("equipmentUsedReportApi Response", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 419) {
        check419();
      } else {
        console.error("Error in equipmentUsedReportApi:", error);
        throw error;
      }
    });
}

function equipmentUsedReportDownloadApi(search) {
  return axios
    .post(
      process.env.REACT_APP_APIURL + "products/equipmentUsedReportDownloadApi",
      JSON.stringify(search),
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("equipmentUsedReportDownloadApi Response", response.data);
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 419) {
        check419();
      } else {
        console.error("Error in equipmentUsedReportDownloadApi:", error);
        throw error;
      }
    });
}
