import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../../Navbar";
import { Redirect, Link } from "react-router-dom";
import dateFormat from "dateformat";
import { consignmentService } from "../../../../../services/consignment.services";
import NavbarComponent from "./navbar";
import { ExportSheet } from "react-xlsx-sheet";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";

const CloseConsignmentRequest = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [bookings, setBookings] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchParams, setSearchParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataXlsx, setDataXlsx] = useState([]);
  console.log(setLoading, setDataXlsx);
  const head = [
    { title: "Consignment Id", dataIndex: "Consignment Id" },
    { title: "Hospital Name", dataIndex: "Hospital Name" },
    { title: "Contact Person", dataIndex: "Contact Person" },
    { title: "Surgeon Name", dataIndex: "Surgeon Name" },
    { title: "Kit", dataIndex: "Kit" },
    { title: "Delivery Date", dataIndex: "Delivery Date" },
    { title: "Booked By", dataIndex: "Booked By" },
  ];
 
  const getXlsxData = async () => {
    setLoading(true);
    try {
      const res = await consignmentService.getCloseConsignmentsExport({
        ...searchParams,
        page: 1,
        limit: "",
      });
      if (res.status) {
        showXlsData(res?.data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  async function showXlsData(data) {
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("Closed Consignment Report");
    const header1 = [
      "CONSIGNMENT ID",
      "HOSPITAL NAME",
      "CONTACT PERSON",
      "SURGEON NAME",
      "KITS",
      "DELIVERY DATE",
      "NOTES",
      "BOOKED BY",
    ];
    const header1Row = ws.addRow(header1);
    header1Row.eachCell((cell) => {
      cell.font = {
        bold: true,
        size: 11,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCC0DA" }, 
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

  
    if (data?.length) {
      console.log(";:::", data);
      let kitset = [];
      let rowIndex = 1;
      data &&
        data.map((item) => {
          console.log("item", item);
          const surgeonNames = [];
          kitset = [];
          item.consignmentKits &&
            item.consignmentKits.map((kset) => {
              if (kitset.length) {
                kitset.push(kset?.kits[0]?.name);
              } else {
                kitset.push(kset?.kits[0]?.name);
              }
            });
          console.log("kitset", kitset); // Collect surgeon names
          item.surgeonData?.forEach((surgeon) => {
            const fullName = `${surgeon?.first_name} ${surgeon?.last_name}`;
            if (fullName && !surgeonNames.includes(fullName)) {
              surgeonNames.push(fullName);
            }
          });
          const surgeonsToAdd = surgeonNames.length > 0 ? surgeonNames : [""];

          const rowData = [
                 item?.consignments?.consignment_id,
            item?.hospitalData[0]?.name,
            item?.hospitalData[0]?.contact_person_name,
            surgeonsToAdd.join(", "), // Combine all surgeons into one cell
            item.consignments?.delivery_date? moment(item.consignments?.delivery_date).format("ddd, MMMM Do, YYYY") : "",
            item?.consignments?.note,
            item?.createdData[0]?.first_name && 
            item?.createdData[0]?.last_name ? item?.createdData[0]?.first_name +
            " " +
            item?.createdData[0]?.last_name:"",
    ];

          const setsArray = kitset;
          console.log("LLL", kitset, setsArray);
          if (setsArray?.length) {
            for (let i = 0; i < setsArray.length; i++) {
              if (i == 0) {
                const mainDataRow = [
                  ...rowData.slice(0, 4),
                  setsArray[i],
                  ...rowData.slice(4, 7),
                ];
                ws.addRow(mainDataRow);
                rowIndex += 1;
              } else {
                const setRow = ["", "", "","", setsArray[i], "", "", ""]; // Empty values for name, address, and age, set value for sets column on subsequent rows of sets
                ws.addRow(setRow);

                if (setsArray.length - 1 === i) {
                  // Merge cells in the name, address, and age columns for the first row of each group of sets
                  if (setsArray.length > 1) {
                    ws.mergeCells(
                      rowIndex,
                      1,
                      rowIndex + setsArray.length - 1,
                      1
                    ); // Merge cells in the name column
                    ws.mergeCells(
                      rowIndex,
                      2,
                      rowIndex + setsArray.length - 1,
                      2
                    ); // Merge cells in the address column
                    ws.mergeCells(
                      rowIndex,
                      3,
                      rowIndex + setsArray.length - 1,
                      3
                    ); // Merge cells in the age column
                    ws.mergeCells(
                      rowIndex,
                      4,
                      rowIndex + setsArray.length - 1,
                      4
                    ); // Merge cells in the age column
                    ws.mergeCells(
                      rowIndex,
                      6,
                      rowIndex + setsArray.length - 1,
                      6
                    ); // Merge cells in the age column
                    ws.mergeCells(
                      rowIndex,
                      7,
                      rowIndex + setsArray.length - 1,
                      7
                    ); 
                    ws.mergeCells(
                      rowIndex,
                      8,
                      rowIndex + setsArray.length - 1,
                      8
                    );
                  }
                  rowIndex += setsArray.length ? setsArray.length - 1 : 1; // Increment rowIndex for the next group of sets
                }
              }
            }
          } else {
            const mainDataRow = [ ...rowData.slice(0, 4),
              ...rowData.slice(4, 7)];
            ws.addRow(mainDataRow);
            rowIndex += 1;
          }
        });
    }
    ws.columns.forEach((col) => (col.width = 18));

    ws.columns.forEach((column, columnIndex) => {
      let maxLength = 30;
      // Start from the third row (index 2) to skip the header row
      for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
        const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;

        if (cellValue && cellValue.toString().length > maxLength) {
          maxLength = 40;
        }
      }
      // Set the width of the column based on the maximum length of its content
      column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
    });

    ws.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          };
        });  
      }
    });
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

  
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "Closed Consignment Report.xlsx";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
    setLoading(false);
  }
  



  const fetchBookings = async (searchParams, page = 1) => {
    try {
      setIsLoading(true);
      const res = await consignmentService.getClosedConsignmentRequest({
        ...searchParams,
        page,
      });
      const newBookings = res.data;

      setBookings((prevBookings) => {
        return page === 1 ? newBookings : [...prevBookings, ...newBookings];
      });
      setHasMore(newBookings.length > 0);
      setIsLoading(false);
      if (res.status) {
        setTotalItemsCount(res?.total_count);
      }
    } catch (error) {
      console.error("Error: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    if (Object.keys(searchParams).length > 0) {
      fetchBookings(searchParams, 1);
    }
  }, [searchParams]);

  useEffect(() => {
    console.log("Search Params updated: ", searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (page > 1) {
      fetchBookings(searchParams, page);
    }
  }, [page]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    if (hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, isLoading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (!user) {
    return <Redirect to="/" />;
  }

  if (user.data.user_role !== "admin") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="consignment-request" />
      <div className="container-fluid">
        <div className="d-flex" style={{ alignItems: "center" }}>
          <NavbarComponent setSearchParams={setSearchParams} />
          <div>
            <button
              style={{ marginTop: "40px" }}
              className={
                !isLoading && !loading
                  ? "btn btn-default add_print ml-3"
                  : "btn btn-default add_print ml-3 dropdown-disabled"
              }
              onClick={() =>
                !isLoading && !loading && bookings?.length ? getXlsxData() : ""
              }
              id="ReportDownload" 
            >
              <i className="fa fa-print"></i>
            </button>
          </div>
          <UncontrolledTooltip target={"ReportDownload"}>
              Print
            </UncontrolledTooltip>
          {!isLoading && !loading ? (
            ""
          ) : (
            <UncontrolledTooltip target={"ReportDownload"}>
              {isLoading
                ? "Please wait"
                : loading
                ? "Please wait"
                : "No Record Found"}
            </UncontrolledTooltip>
          )}

          {dataXlsx && (
            <ExportSheet
              header={head}
              fileName={"bookings"}
              dataSource={dataXlsx}
              xlsx={XLSX}
            >
              <span></span>
            </ExportSheet>
          )}
        </div>
        <div
          className="small mr-3 badge badge-info"
          style={{
            fontWeight: 500,
            background: "#5d75e9",
            marginBottom: 0,
          }}
        >
          {totalItemsCount} Records found
        </div>
        <div id="table-scroll" className="table-scroll mt-2">
          <table id="main-table" className="main-table">
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "250px" }}>
                  Hospital Name
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Contact Person
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Surgeon Name
                </th>
                <th scope="col">Kits</th>
                <th scope="col">Delivery Date</th>
                <th scope="col">Booked By</th>
                <th scope="col" className="text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking) => (
                  <tr key={booking.consignments._id}>
                    <td data-th="Hospital Name">
                      <div>
                        <Link
                          to={
                            "/admin/consignment-request/details/" +
                            booking.consignments._id
                          }
                        >
                          <span>
                            <strong>
                              <span
                                style={{ display: "block" }}
                                className="theme-text"
                              >
                                {booking.consignments.consignment_id}
                              </span>
                              {booking.hospitalData[0]?.name}
                            </strong>
                          </span>
                          <br />
                          <small>
                            Booked on{" "}
                            {dateFormat(
                              booking.consignments.createdAt,
                              "ddd, mmm dS"
                            )}
                          </small>
                        </Link>
                      </div>
                    </td>
                    <td data-th="Contact Person">
                      <div className="info-left">
                        <strong>
                          {booking.hospitalData[0]?.contact_person_name}{" "}
                        </strong>
                        <br />
                        <small>
                          Location: {booking.consignments.hospital_address}{" "}
                        </small>
                        <br />
                        <small>Email: {booking.hospitalData[0]?.email}</small>
                        <br />
                        <small>
                          Phone:{" "}
                          {booking.hospitalData[0]?.contact_person_phone_number}
                        </small>
                        <br />
                        <small>
                          Department: {booking.consignments.hospital_department}
                        </small>
                      </div>
                    </td>
                    <td data-th="Surgeon Name">
                      <div className="info-left">
                        {booking.surgeonData.length > 0
                          ? booking.surgeonData.map((surgeon) => (
                              <div key={surgeon._id}>
                               
                                  {surgeon.title} {surgeon.first_name}{" "}
                                  {surgeon.last_name}
                                <br />
                              </div>
                            ))
                          : "Not Assigned"}
                      </div>
                    </td>
                    <td data-th="Kits">
                      <small>
                        {booking.consignmentKits.length > 0
                          ? booking.consignmentKits.map((kit) => (
                              <a
                                key={kit._id}
                                href="javascript:;"
                                data-toggle="modal"
                                data-target="#kitDetails"
                              >
                                {kit.kits[0]?.name} <br />
                              </a>
                            ))
                          : "None"}
                      </small>
                    </td>
                    <td data-th="Delivery Date">
                      {booking?.consignments?.delivery_date
                        ? dateFormat(
                            booking?.consignments?.delivery_date,
                            "ddd, mmm dS"
                          )
                        : ""}
                    </td>
                    <td data-th="Booked By">
                      <div className="info-left">
                        {booking.createdData[0]?.first_name}{" "}
                        {booking.createdData[0]?.last_name}
                      </div>
                    </td>
                    <td>
                      <div data-th="Status" className="mb-2">
                        <span className={"capsule " + "green-dim " + " mb-1"}>
                          Closed
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="7">No Bookings Found</td>
                </tr>
              )}
            </tbody>
          </table>
          {isLoading && (
            <div className="text-center">
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CloseConsignmentRequest;
