import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../../GlobalComponents/Footer";
import Navbar from "../../Navbar";
import { repsBookingService } from "../../../../../services/repsBooking.services";
import Select from "react-select";
import useDebounce from "../../../../../helpers/UseDebounce";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Norecord from "./Norecord";
import { bookingService } from "../../../../../services";
import { ExportSheet } from "react-xlsx-sheet";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";
import { EquipmentUsedReportService } from "../../../../../services/equipmentUsedReport.services";

const statusOptions = [
  { value: "", label: "All" },
  { value: "yes", label: "Filled" },
  { value: "no", label: "Empty" },
];

const RepsItemUsedList = () => {
  // const [dataXlsx, setDataXlsx] = useState([]);
  const [hospitals, setHospitals] = useState([
    { label: "All Hospital", value: "" },
  ]);
  const [hospitalsList, setHospitalsList] = useState([]);
  const [search, setSearch] = useState("");
  const searchRef = useRef(null);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([
    { value: "", label: "All" },
  ]);
  const [firstLoad, setFirstLoad] = useState(0);
  const [selectedHospital, setSelectedHospital] = useState([
    { label: "Search by Hospital", value: "" },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataXlsx, setDataXlsx] = useState([]);
  console.log(setLoading, setDataXlsx);
  const head = [
    { title: "BOOKING ID", dataIndex: "BOOKING ID" },
    { title: "HOSPITAL NAME", dataIndex: "HOSPITAL NAME" },
    { title: "SURGEON NAME", dataIndex: "SURGEON NAME" },
    { title: "OPERATION NAME", dataIndex: "OPERATION NAME" },
    { title: "OPERATION DATE", dataIndex: "OPERATION DATE" },
    { title: "PRODUCT CODE", dataIndex: "PRODUCT CODE" },
    { title: "PRODUCT DESCRIPTION", dataIndex: "PRODUCT DESCRIPTION" },
    { title: "BRAND", dataIndex: "BRAND" },
    { title: "QUANTITY", dataIndex: "QUANTITY" },
    { title: "LOT NUMBER", dataIndex: "LOT NUMBER" },
    { title: "USED/IMPLANTED", dataIndex: "USED/IMPLANTED" },
    { title: "OUTCOME OF THE CASE ", dataIndex: "OUTCOME OF THE CASE " },
    { title: "NOTES", dataIndex: "NOTES" },
  ];

  const getXlsxData = async () => {
    setIsLoading(true);
    try {
      const res = await EquipmentUsedReportService.getAllItemUsedExport({
        type: ["loan"],
        status: ["dispatch", "booked"],
        search: search,
        page: activePage,
        limit: "",
        reps_id: user?.data?._id,
        hospitalId: selectedHospital[0]?.value,
        itemUsedProduct_filter: status,
      });
      if (res.status) {
        showXlsData(res?.data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  async function showXlsData(data) {
    console.log("data xl", data);
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("Item Used Report");
    const header1 = [
      "BOOKING ID",
      "HOSPITAL",
      "SURGEON NAME",
      "OPERATION NAME",
      "OPERATION DATE",
      "PRODUCT CODE",
      "PRODUCT DESCRIPTION",
      "BRAND",
      "QUANTITY",
      "LOT NUMBER",
      "USED/IMPLANTED",
      "OUTCOME OF THE CASE",
      "NOTES",
    ];

    const header1Row = ws.addRow(header1);
    header1Row.eachCell((cell) => {
      cell.font = { bold: true, size: 11 };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCC0DA" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    if (data?.length) {
      let rowIndex = 2;

      data.forEach((item) => {
        console.log("@@@@@@@@@", item);
        const rowData = [
          item?.bookings?.booking_id,
          item?.hospitalData[0]?.name ? item?.hospitalData[0]?.name : "",
          `${item?.bookingSurgeonData[0]?.SurgeonDetail[0]?.first_name} ${item?.bookingSurgeonData[0]?.SurgeonDetail[0]?.last_name}`,
          item?.bookings?.operation_name,
          item.bookings?.operation_date
            ? moment(item.bookings.operation_date).format("ddd, MMMM Do, YYYY")
            : "",
        ];

        const productDetails = item.itemUsedProducts || [];

        // If there are product details, add them
        if (productDetails.length > 0) {
          productDetails.forEach((product, index) => {
            console.log("product", product);
            const productRow = [
              index === 0 ? rowData[0] : "",
              index === 0 ? rowData[1] : "",
              index === 0 ? rowData[2] : "",
              index === 0 ? rowData[3] : "",
              index === 0 ? rowData[4] : "",
              product?.productData?.manufacturers_product_code,
              product.productData.name,
              product.productData.product_brand,
              product.quantity_of_used_item,
              product.lot_number,
              product.item_in_used ? "Yes" : "No",
              item?.bookingProductsReviews[0]?.review === "satisfactory"
              ? "Satisfactory"
              : item?.bookingProductsReviews[0]?.review === "not_satisfactory"
              ? "Not Satisfactory"
              : item?.bookingProductsReviews[0]?.review === "other"
              ? "Other"
               : item?.bookingProductsReviews[0]?.review === "na"
              ? "NA"
              : "",        
              item?.bookingProductsReviews[0]?.description || "",
            ];
            ws.addRow(productRow);
            rowIndex += 1;
          });

          // Apply merging for multi-product rows for the current booking
          if (productDetails.length > 1) {
            ws.mergeCells(rowIndex - productDetails.length, 1, rowIndex - 1, 1); // BOOKING ID
            ws.mergeCells(rowIndex - productDetails.length, 2, rowIndex - 1, 2); // HOSPITAL
            ws.mergeCells(rowIndex - productDetails.length, 3, rowIndex - 1, 3); // SURGEON NAME
            ws.mergeCells(rowIndex - productDetails.length, 4, rowIndex - 1, 4); // OPERATION NAME
            ws.mergeCells(rowIndex - productDetails.length, 5, rowIndex - 1, 5); // OPERATION DATE
            ws.mergeCells(
              rowIndex - productDetails.length,
              12,
              rowIndex - 1,
              12
            ); // OUTCOME OF THE CASE
            ws.mergeCells(
              rowIndex - productDetails.length,
              13,
              rowIndex - 1,
              13
            ); // NOTES
          }
        } else {
          // If no product details, add a row with only the booking data
          const productRow = [
            rowData[0],
            rowData[1],
            rowData[2],
            rowData[3],
            rowData[4],
            "", // PRODUCT CODE
            "", // PRODUCT DESCRIPTION
            "", // BRAND
            "", // QUANTITY
            "", // LOT NUMBER
            "", // USED/IMPLANTED
            item.outcome_of_the_case || "",
            item.notes || "",
          ];
          ws.addRow(productRow);
          rowIndex += 1;
        }
      });

      ws.columns.forEach((col) => (col.width = 25));
      ws.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          row.eachCell((cell) => {
            cell.border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
            };
            cell.alignment = {
              vertical: "middle",
              horizontal: "left",
              wrapText: true,
            };
          });
        }
      });

      const excelBlob = await workbook.xlsx.writeBuffer();
      const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = excelUrl;
      link.download = "Item Used Report.xlsx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(excelUrl);
      document.body.removeChild(link);
      setIsLoading(false);
    }
  }

  const getHospitals = async () => {
    const result = await bookingService.getHospitalsList({ search: search });
    console.log("hospital", result);
    setHospitalsList(result.data);
  };

  const setSelectedOptionHospital = (e) => {
    var selectedHospital = [{ value: e.value, label: e.label }];
    setSelectedHospital(selectedHospital);
  };

  const showHospitalsList = (hospitalsList) => {
    var tempHospitals = [{ label: "Search by Hospital", value: "" }];
    hospitalsList.map((item) => {
      var option = { value: item._id, label: item.name };
      tempHospitals.push(option);
    });
    setHospitals(tempHospitals);
  };

  useEffect(() => {
    if (hospitalsList !== undefined) {
      showHospitalsList(hospitalsList);
    }
  }, [hospitalsList]);

  const handleSearch = (val) => {
    setSearch(val);
    setActivePage(1);
    //debouncedSearch(val);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    if (pageNumber !== activePage) {
      setLoading(true);
      window.scrollTo({ top: 0 });
    }
    // getList();
  };

  const getList = async () => {
    setBookingList([]);
    setLoading(true);
    setFirstLoad(1);
    try {
      const res = await repsBookingService.getAllBookingForItemUsed({
        type: ["loan"],
        status: ["dispatch", "booked"],
        search: search,
        page: activePage,
        limit: 50,
        reps_id: user?.data?._id,
        hospitalId: selectedHospital[0]?.value,
        itemUsedProduct_filter: status,
      });

      setBookingList(res.data);
      setTotalItemsCount(res.total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleStatus = (e) => {
    setActivePage(1);
    setStatus(e.value);
    setSelectedStatus(e);
  };

  useDebounce(
    () => {
      if (firstLoad != 0) getList();
    },
    [search],
    800
  );

  useEffect(() => {
    getList();
  }, [activePage, status, selectedHospital]);

  useEffect(() => {
    searchRef.current.focus();
    getHospitals();
  }, []);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  if (!user || user.data.user_role !== "reps") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="item-used" />
      <main className="offset">
        <div className="container-fluid">
          <div className="row pt-4">
            <div className="col-lg-12">
              <div className="ground">
                <div className="row">
                  <div className="col-lg-4 col-md-12 mb-2">
                    <div className="sub-menu mb-0 mb-sm-3">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <Link
                          type="button"
                          className="btn btn-secondary"
                          to="/reps/item-used"
                        >
                          Today
                        </Link>
                        <Link
                          type="button"
                          className="btn btn-secondary mx-1"
                          to="/reps/item-used/tomorrow"
                        >
                          Tomorrow
                        </Link>
                        <Link
                          type="button"
                          className="btn btn-secondary"
                          to="/reps/item-used/past"
                        >
                          Past Bookings
                        </Link>
                        <Link
                          type="button"
                          className="btn btn-secondary btn-disabled active mx-1"
                          to="/reps/item-used/all"
                        >
                          All Bookings
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 mb-2">
                    <div className="form-search">
                      <input
                        className="form-control mr-sm-2"
                        type="text"
                        placeholder="Search by Booking ID"
                        value={search}
                        ref={searchRef}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-sm-6 mb-2">
                    <div className="row no-gutters">
                      <div className="col-lg-5 col-12 mr-5 mb-2">
                        <div className="label_adjust">
                          <label className="small mb-0"></label>
                          <Select
                            value={selectedHospital}
                            onChange={setSelectedOptionHospital}
                            options={hospitals}
                            className="small text-left"
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 col-12">
                        <div className="label_adjust">
                          <label className="small mb-0">Item Used</label>
                          <Select
                            placeholder="Item Used"
                            value={selectedStatus}
                            onChange={handleStatus}
                            options={statusOptions}
                            className="text-left"
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          // style={{ marginTop: "40px" }}
                          className={
                            !isLoading && !loading
                              ? "btn btn-default add_print ml-3"
                              : "btn btn-default add_print ml-3 dropdown-disabled"
                          }
                          onClick={() =>
                            !isLoading && !loading && bookingList?.length
                              ? getXlsxData()
                              : ""
                          }
                          id="ReportDownload"
                        >
                          <i className="fa fa-print"></i>
                        </button>
                      </div>
                      <UncontrolledTooltip target={"ReportDownload"}>
                        Print
                      </UncontrolledTooltip>
                      {!loading && !isLoading ? (
                        ""
                      ) : (
                        <UncontrolledTooltip target={"ReportDownload"}>
                          {loading
                            ? "Please wait"
                            : isLoading
                            ? "Please wait"
                            : "No Record Found"}
                        </UncontrolledTooltip>
                      )}

                      {dataXlsx && (
                        <ExportSheet
                          header={head}
                          fileName={"item used"}
                          dataSource={dataXlsx}
                          xlsx={XLSX}
                        >
                          <span></span>
                        </ExportSheet>
                      )}
                    </div>
                  </div>
                </div>

                <div className="table-scroll mt-4">
                  <Table className="table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            minWidth: "200px",
                          }}
                        >
                          Booking #
                        </th>
                        <th scope="col">Hospital Name </th>
                        <th scope="col">Operation Name </th>

                        <th scope="col">Item Used </th>
                        <th scope="col" className="text-manage">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingList &&
                        bookingList.map((item, index) => (
                          <tr key={item._id + "-" + index}>
                            <td width="200" data-th="Booking #">
                              <Link
                                to={
                                  "/reps/booking/details/" + item.bookings._id
                                }
                              >
                                <span>
                                  <strong> {item.bookings.booking_id} </strong>
                                </span>
                              </Link>
                            </td>
                            <td data-th="Hospital Name">
                              {item.hospitalId.name}
                            </td>
                            <td data-th="Operation Name" className="">
                              <div>{item.bookings.operation_name}</div>
                            </td>

                            <td data-th="Filled Status">
                              <span
                                className={
                                  "capsule " +
                                  (item.itemUsedProducts.length > 0
                                    ? "green-dim "
                                    : "red-dim ") +
                                  " mr-2 mb-1"
                                }
                              >
                                {item.itemUsedProducts.length > 0
                                  ? "Filled"
                                  : "Empty"}
                              </span>
                            </td>

                            <td className="text-manage">
                              <div className="action-area dropdown">
                                {item.bookings.status == "dispatch" && (
                                  <div className="mb-2">
                                    <span>
                                      <Link
                                        className="btn btn-outline-primary btn-sm1"
                                        to={
                                          "/reps/booking/item-used/o/" +
                                          item.bookings._id
                                        }
                                      >
                                        Item Used
                                      </Link>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      {loading == true && <Norecord />}

                      {bookingList &&
                        bookingList.length == 0 &&
                        firstLoad != 0 &&
                        loading == false && (
                          <tr className="text-center">
                            <td colSpan="7">No Record Found</td>
                          </tr>
                        )}
                    </tbody>
                  </Table>
                </div>
              </div>

              {bookingList && bookingList.length > 0 && (
                <div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center mb-5"
                    activeLinkClass="active"
                    nextPageText="Next"
                    prevPageText="Prev"
                  />
                  <br className="d-block d-sm-none" />
                  <br className="d-block d-sm-none" />
                </div>
              )}

              {/* Dispatch Details Modal */}
            </div>
          </div>
          {/*<!--/row-->*/}
        </div>
      </main>

      <Footer />
    </div>
  );
};
export default RepsItemUsedList;
