import { authHeader, check419 } from "../helpers";

export const consignmentService = {
  dispatchConsingnment,
  rejectConsingnment,
  acceptConsingnment,
  closeConsignment,
  getConsignmentRequest,
  getClosedConsignmentRequest,
  getConsignmentsExport,
  getRejectConsignmentsExport,
  getCloseConsignmentsExport
};

function getCloseConsignmentsExport(search) {
  console.log("params", search);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };

  return fetch(
    process.env.REACT_APP_APIURL + "admin/consinment-request/get-close-export",
    requestOptions
  )
    .then(handleResponse)
    .then((bookingList) => {
      return bookingList;
    });
}

function getRejectConsignmentsExport(search) {
  console.log("params", search);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };

  return fetch(
    process.env.REACT_APP_APIURL + "admin/consinment-request/get-reject-export",
    requestOptions
  )
    .then(handleResponse)
    .then((bookingList) => {
      return bookingList;
    });
}

function getConsignmentsExport(search) {
  console.log("params", search);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };

  return fetch(
    process.env.REACT_APP_APIURL + "admin/consinment-request/get-list-export",
    requestOptions
  )
    .then(handleResponse)
    .then((bookingList) => {
      return bookingList;
    });
}

function dispatchConsingnment(search) {
  console.log("params", search);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };

  return fetch(
    process.env.REACT_APP_APIURL + "admin/consinment-request/do-dispatch",
    requestOptions
  )
    .then(handleResponse)
    .then((bookingList) => {
      return bookingList;
    });
}

function rejectConsingnment(search) {
  console.log("params", search);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };

  return fetch(
    process.env.REACT_APP_APIURL + "admin/consinment-request/do-reject",
    requestOptions
  )
    .then(handleResponse)
    .then((bookingList) => {
      return bookingList;
    });
}

function closeConsignment(search) {
  console.log("params", search);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };

  return fetch(
    process.env.REACT_APP_APIURL + "admin/consinment-request/do-close",
    requestOptions
  )
    .then(handleResponse)
    .then((bookingList) => {
      return bookingList;
    });
}

function getConsignmentRequest(search) {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(search),
    };
  
    return fetch(
      process.env.REACT_APP_APIURL + "admin/consinment-request/get-by-id",
      requestOptions
    )
      .then(handleResponse)
      .then((hospitalsList) => {
        return hospitalsList;
      });
  }


function getClosedConsignmentRequest(search) {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(search),
    };
  
    return fetch(
      process.env.REACT_APP_APIURL + "admin/consinment-request/get-close",
      requestOptions
    )
      .then(handleResponse)
      .then((hospitalsList) => {
        return hospitalsList;
      });
  }

function acceptConsingnment(search) {
  console.log("params", search);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(search),
  };

  return fetch(
    process.env.REACT_APP_APIURL + "admin/consinment-request/do-accept",
    requestOptions
  )
    .then(handleResponse)
    .then((bookingList) => {
      return bookingList;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    // const data = text;
    if (!response.ok) {
      if (response.status === 400) {
        // auto logout if 401 response returned from api
        // logout();
        // location.reload(true);
        const error = (data && data.data.errors) || response.statusText;
        return Promise.reject(error);
      } else if (response.status === 419) {
        check419();
      }

      // const error = (data && data.message) || response.statusText;
      // return Promise.reject(error);
    }

    return data;
  });
}

