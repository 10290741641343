// import { userConstants } from "../constants";
// import { userService } from "../services";
// import { alertActions } from ".";
// import { history } from "../helpers";

// export const authActions = {
//     login,
//     logout,
//     forgotPassword,
//     resetPassword
// };

// function login(email, otp) {
//     return dispatch => {
//         dispatch(request({ email }));

//         userService.login(email, otp)
//             .then(
//                 user => { 
//                     dispatch(success(user));
//                     if(user.data.user_role === "admin" || user.data.user_role === "super_admin") {
//                         history.push("/admin/dashboard/booking-dispatch");
//                     }
//                     if(user.data.user_role === "reps") {
//                         history.push("/reps/dashboard");
//                     }
//                 },
//                 error => {
//                     dispatch(failure(error));
//                     // dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
//     function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
//     function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }
// }

// function logout() {
//     userService.logout();
//     //     .then(
//     //         loggedOut => {
//     //             history.push("/");
//     //         }
//     //     );
//     history.push("/");
//     return { type: userConstants.LOGOUT };
// }

// function forgotPassword(email) {
//     return dispatch => {
//         dispatch(request({ email }));

//         userService.forgotPassword(email)
//             .then(
//                 msg => { 
//                     dispatch(success(msg));
//                 },
//                 error => {
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(email) { return { type: userConstants.FORGOTPASSWORD_REQUEST, email }; }
//     function success(msg) { return { type: userConstants.FORGOTPASSWORD_SUCCESS, msg }; }
//     function failure(error) { return { type: userConstants.FORGOTPASSWORD_FAILURE, error }; }
// }

// function resetPassword(resetPassword) {
//     return dispatch => {
//         dispatch(request({ resetPassword }));

//         userService.resetPassword(resetPassword)
//             .then(
//                 msg => { 
//                     dispatch(success(msg));
//                 },
//                 error => {
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(email) { return { type: userConstants.FORGOTPASSWORD_REQUEST, email }; }
//     function success(msg) { return { type: userConstants.FORGOTPASSWORD_SUCCESS, msg }; }
//     function failure(error) { return { type: userConstants.FORGOTPASSWORD_FAILURE, error }; }
// }


import { userConstants } from "../constants";
import { userService } from "../services";
import { alertActions } from ".";
import { history } from "../helpers";

export const authActions = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    verifyOtp
};
function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        return userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    return user; 
                },
                error => {
                    dispatch(failure(error));
                    return Promise.reject(error);
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }
}

function verifyOtp(email, otp) {
    return dispatch => {
        dispatch(request({ email }));
        return userService.verifyOtp(email, otp)
            .then(
                response => {
                    const user = response.data;

                    if (response.status === 200) {
                        if (user?.user_role === "admin" || user?.user_role === "super_admin") {
                            history.push("/admin/dashboard/booking-dispatch");
                        } else if (user?.user_role === "reps") {
                            history.push("/reps/dashboard");
                        }
                    }
                    
                    dispatch(success(response));
                    return response; 
                },
                error => {
                    dispatch(failure(error));
                    return Promise.reject(error); 
                }
            );
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }
   }



function logout() {
    userService.logout();
   history.push("/");
    return { type: userConstants.LOGOUT };
}

function forgotPassword(email) {
    return dispatch => {
        dispatch(request({ email }));

        userService.forgotPassword(email)
            .then(
                msg => { 
                    dispatch(success(msg));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: userConstants.FORGOTPASSWORD_REQUEST, email }; }
    function success(msg) { return { type: userConstants.FORGOTPASSWORD_SUCCESS, msg }; }
    function failure(error) { return { type: userConstants.FORGOTPASSWORD_FAILURE, error }; }
}

function resetPassword(resetPassword) {
    return dispatch => {
        dispatch(request({ resetPassword }));

        userService.resetPassword(resetPassword)
            .then(
                msg => { 
                    dispatch(success(msg));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: userConstants.FORGOTPASSWORD_REQUEST, email }; }
    function success(msg) { return { type: userConstants.FORGOTPASSWORD_SUCCESS, msg }; }
    function failure(error) { return { type: userConstants.FORGOTPASSWORD_FAILURE, error }; }
}