import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  // Button,
  Input,
} from "reactstrap";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { bookingActions, kitActions, setActions } from "../../../../actions";
import { bookingService } from "../../../../services";
import dateFormat from "dateformat";
import Select from "react-select";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import moment from "moment";
import { history } from "../../../../helpers/";

class Details extends Component {
  constructor(props) {
    super(props);
    // reset login status
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      updateBookingInfo: {
        pickup_location_text: "",
        id: "",
        hospitalId: "",
        hospital_address: "",
        po_number: "",
        po_check: false,
        operation_name: "",
        shipping_charge: "",
        additional_notes: "",
        assigned_rep: "",
        additional_reps: [],
        delivery_date: "",
        delivery_date_slot: "",
        operation_date: "",
        operation_date_slot: "",
        collection_date: "",
        booking_type: "",
        kitData: [],
        kitSetData: [],
        return_date: "",
        status: "",
      },
      returnData: {
        courier: "",
        return_date: "",
        additional_note: "",
        returnBoxesData: [],
        returnSetData: [],
        bookingId: "",
        log_status: "",
        log_by_id: "",
      },
      bookingId: "",
      showDateInput: true,
      boxArray: [{ box_type: "", number_of_box: "" }],
      returnKit: [],
      operationName: "",
      operationOther: "",
      hospitalName: "",
      surgeonName: "",
      repName: "",
      repEmail: "",
      bookingInfo: {},
      selectedRep: [],
      selectedAdditionalRep: [],
      reps: [],
      additionalReps: [],
      hospitals: [],
      hospitalDetails: [],
      repsDetails: [],
      hospitalAddress: [],
      hospitalContactPerson: "",
      hospitalContactPersonEmail: "",
      hospitalContactPersonPhone: "",
      searchHospital: "",
      searchReps: "",
      kits: [],
      kitListFlag: 0,
      selectedKit: [],
      kitWithKitSet: [],
      kitSetDetailModal: false,
      kitDetails: [],
      kitDetailsClickFlag: 0,
      dispatchModal: false,
      returnModal: false,
      returnSubmitFlag: 0,
      returnSetDataLength: 0,
      redirect: false,
      logList: [],
      submitDispatchFlag: 0,
      dispatch: {
        courier: "",
        dispatchBoxesData: [
          { box_type: "", number_of_box: "", numberOfBoxFlag: false },
        ],
        tracker: "",
        bookingId: 0,
        log_status: "",
        log_by_id: "",
        notes: "",
        dispatched_by: "Lily Rose Winters",
        other_dispatched_by: "",
      },

      boxes: [{ box_type: "", number_of_box: "", numberOfBoxFlag: false }],
      error: "",
      isEditable: false,
      editablePoNumber: "",
      // selectedTrackers: [],
      options: [
        { label: "All Aces", value: "All Aces" },
        { label: "APC", value: "APC" },
        { label: "Sameday (HBC)", value: "Sameday (HBC)" },
        { label: "Other", value: "Other" },
      ],
      selectedOption: [{ label: "All Aces", value: "All Aces" }],
      boxOptions: [
        { label: "Black Box", value: "Black Box" },
        { label: "Blue Box", value: "Blue Box" },
        { label: "Grey case", value: "Grey case" },
        { label: "Cardboard Box", value: "Cardboard Box" },
      ],
      selectedNumOfBox: [{ label: "Black Box", value: "Black Box" }],
      dispatchedByOptions: [
        { label: "Lily Rose Winters", value: "Lily Rose Winters" },
        {
          label: "Ekaterina Karateodorova",
          value: "Ekaterina Karateodorova",
        },
        { label: "George Viziteu", value: "George Viziteu" },
        { label: "Ana Marie Sendrea", value: "Ana Marie Sendrea" },
        { label: "Jeff Allen", value: "Jeff Allen" },
        { label: "Others", value: "Other" },
      ],
      selectedDispatchedByOptions: [
        { label: "Lily Rose Winters", value: "Lily Rose Winters" },
      ],
      selectedDispatchedByValue: "",
      otherDispatchedFlag: false,
      additionalRepsNames: [],
      bookingReturns: [],
      showMore: false,
      showMoreId: "",
      bilateralCase: "",
    };

    this.getList = this.getList.bind(this);
    this.getSingleBooking = this.getSingleBooking.bind(this);
    this.getHospitalAddressList = this.getHospitalAddressList.bind(this);
    this.showHospitalsList = this.showHospitalsList.bind(this);

    this.handleBookingAddress = this.handleBookingAddress.bind(this);
    this.handleKitDetails = this.handleKitDetails.bind(this);
    this.showKitDetails = this.showKitDetails.bind(this);
    this.kitSetDetailModalClose = this.kitSetDetailModalClose.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.returnModalClose = this.returnModalClose.bind(this);
    this.handleDispatch = this.handleDispatch.bind(this);
    this.dispatchModalOpen = this.dispatchModalOpen.bind(this);

    this.handleCourier = this.handleCourier.bind(this);
    this.handleReturnDate = this.handleReturnDate.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.submitReturnData = this.submitReturnData.bind(this);
    this.handleDispatchCourier = this.handleDispatchCourier.bind(this);
    this.handlePoNumber = this.handlePoNumber.bind(this);
    this.showMoreFunction = this.showMoreFunction.bind(this);
  }

  getSingleBooking(id) {
    this.props.dispatch(bookingActions.getById(id));
    this.props.dispatch(bookingActions.getLog({ bookingId: id }));
  }

  getList() {
    this.props.dispatch(
      bookingActions.getHospitalsList({
        search: this.state.searchHospital,
      })
    );
    this.props.dispatch(
      bookingActions.getRepsList({ search: this.state.searchReps })
    );
    this.props.dispatch(kitActions.kitListDropdown({ search: "" }));
  }

  getHospitalAddressList(id) {
    this.props.dispatch(bookingActions.getHospitalDetailsById(id));
  }

  setBookingData(data) {
    var hospitalName = "";
    var defaultRepsLable = "";
    var defaultHospital = this.state.hospitals.find(
      (val) => val.value === data.hospitalId
    );
    if (defaultHospital) {
      hospitalName = defaultHospital.label;
    }
    var defaultReps = this.state.reps.find(
      (val) => val.value === data.assigned_rep
    );

    if (defaultReps) {
      defaultRepsLable = defaultReps.label;
    }
    var defaultAdditionalReps = [];
    var additionalRepsName = [];

    data.additional_reps.map((repValue) => {
      defaultAdditionalReps.push(
        this.state.additionalReps.find((val) => val.value === repValue)
      );
    });

    data.additionalRepsData.map((repValue) => {
      let repsName = repValue?.first_name + " " + repValue?.last_name;
      additionalRepsName.push(repsName);
    });

    const { updateBookingInfo } = this.state;
    updateBookingInfo["id"] = data["_id"];
    updateBookingInfo["operation_name"] = data["operation_name"];
    updateBookingInfo["hospitalId"] = data["hospitalId"];
    updateBookingInfo["hospital_address"] = data["hospital_address"];
    updateBookingInfo["operation_date_slot"] = data["operation_date_slot"];
    updateBookingInfo["po_number"] = data["po_number"];
    updateBookingInfo["po_check"] = data["po_check"]; 
    updateBookingInfo["shipping_charge"] = data["shipping_charge"];
    updateBookingInfo["delivery_date_slot"] = data["delivery_date_slot"];
    updateBookingInfo["assigned_rep"] = data["assigned_rep"];
    updateBookingInfo["status"] = data["status"];
    updateBookingInfo["delivery_date"] = dateFormat(
      data["delivery_date"],
      "yyyy-mm-dd"
    );
    updateBookingInfo["operation_date"] = dateFormat(
      data["operation_date"],
      "yyyy-mm-dd"
    );
    updateBookingInfo["collection_date"] = dateFormat(
      data["collection_date"],
      "yyyy-mm-dd"
    );
    updateBookingInfo["additional_notes"] = data["additional_notes"];
    updateBookingInfo["booking_type"] = data["booking_type"];
    updateBookingInfo["return_date"] = dateFormat(
      data["return_date"],
      "yyyy-mm-dd"
    );
    updateBookingInfo["pickup_location_text"] = data["pickup_location_text"];

    this.setState({
      editablePoNumber: data["po_number"],
    });

    var tempAdditionalReps = [];
    data.additional_reps.map((item) => {
      tempAdditionalReps.push({ id: item });
    });
    updateBookingInfo["additional_reps"] = tempAdditionalReps;

    //additional_reps

    var surgeonName = "";
    if (data.bookingSurgeonId.length > 0) {
      surgeonName =
        data.bookingSurgeonId[0].surgeonId[0].title +
        " " +
        data.bookingSurgeonId[0].surgeonId[0].first_name +
        " " +
        data.bookingSurgeonId[0].surgeonId[0].last_name;
    }

    this.setState(
      {
        updateBookingInfo,
        bookingInfo: data,
        bookingId: data.booking_id,
        operationName: data.operation_name,
        operationOther: data.other_opration,
        surgeonName: surgeonName,
        hospitalName: hospitalName,
        repName: defaultRepsLable,
        repEmail: "",
        selectedAdditionalRep: defaultAdditionalReps,
        additionalRepsNames: additionalRepsName,
        bookingReturns: data.bookingreturns,
        bilateralCase: data.bilateral_case,
      },
      () => {
        this.getHospitalAddressList(data.hospitalId);
      }
    );

    //console.log("booking ",data);

    data.bookingKitId.map((item) => {
      this.props.dispatch(bookingActions.getKitSetByKit({ kitId: item.kitId }));
    });
  }

  showHospitalsList(hospitalsList) {
    var tempHospitals = [];
    hospitalsList.map((item) => {
      var option = { value: item._id, label: item.name };
      tempHospitals.push(option);
    });
    this.setState({
      hospitalDetails: hospitalsList,
      hospitals: tempHospitals,
    });
  }

  showAddressList(address) {
    var hospitalAddress = [];
    address.addresses.map((item) => {
      if (this.state.bookingInfo.hospital_address === item.name) {
        hospitalAddress.push({
          _id: item._id,
          name: item.name,
          checked: true,
        });
      }
    });

    this.setState(
      {
        hospitalAddress: hospitalAddress,
        hospitalContactPerson: address.contact_person_name,
        hospitalContactPersonEmail: address.email,
        hospitalContactPersonPhone: address.contact_person_phone_number,
      },
      () => {
        //console.log(this.state.hospitalAddress);
      }
    );
  }

  showRepsList(repsList) {
    var tempRepsList = [];
    repsList.map((item) => {
      var option = {
        value: item._id,
        label: item.first_name + " " + item.last_name,
      };
      tempRepsList.push(option);
    });
    this.setState({
      repsDetails: repsList,
      reps: tempRepsList,
      additionalReps: tempRepsList,
    });
  }

  showKitsList(kitList) {
    var tempKitList = [];
    kitList.map((item) => {
      var option = { value: item._id, label: item.name };
      tempKitList.push(option);
    });
    this.setState({
      kits: tempKitList,
      kitListFlag: 1,
    });
  }

  showKitsSetList(data) {
    const { kitWithKitSet, updateBookingInfo, bookingInfo } = this.state;
    var subKitSet = [];
    var lnt = 0;
    var hire_charge = "";
    var status = "";

    data[0]["kitsets"].map((kset) => {
      hire_charge = "";
      status = "";
      lnt = data[0].bookingKitSets.filter(
        (rs) => rs.setId === kset._id && rs.bookingId == updateBookingInfo.id
      ).length;
      if (lnt > 0) {
        data[0].bookingKitSets
          .filter(
            (rs) =>
              rs.setId === kset._id && rs.bookingId == updateBookingInfo.id
          )
          .map((result) => {
            hire_charge = result.hire_charge;
            status = result.status;
          });
      }
      subKitSet.push({
        set_name: kset.set_name,
        _id: kset._id,
        hire_charge: hire_charge,
        added: lnt,
        status: status,
        isreturn: false,
      });
    });

    var kitquantity = "";
    var kithire_charge = "";

    bookingInfo.bookingKitId
      .filter((item) => item.kitId == data[0]._id)
      .map((maini) => {
        kithire_charge = maini.hire_charge;
        kitquantity = maini.quantity;
      });
    kitWithKitSet.push({
      name: data[0].name,
      _id: data[0]._id,
      hire_charge: kithire_charge,
      quantity: kitquantity,
      sets: subKitSet,
    });

    var kits = this.state.kits;
    var selectedVal = data[0]._id;
    kits = kits.filter((rs) => rs.value !== selectedVal);

    this.setState({
      kitWithKitSet,
      selectedKit: [],
      kits: kits,
    });
  }

  handlePoNumber(val) {
    this.setState({
      editablePoNumber: val,
    });
  }

  updatePONumber(bookingId) {
    bookingService
      .updatePoNumber({
        id: bookingId,
        po_number: this.state.editablePoNumber,
        po_check :false
      })
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            isEditable: false,
            kitWithKitSet: [],
          });
          this.getSingleBooking(this.props.match.params.id);
        }
      })
      .catch((error) => {
        this.setState({
          error: error.common[0],
        });
      });
  }

  handleBookingAddress(value, checked, ind) {
    const { updateBookingInfo, hospitalAddress } = this.state;
    hospitalAddress.map((item) => {
      item.checked = false;
    });
    hospitalAddress[ind].checked = true;
    this.setState({
      hospitalAddress,
    });

    updateBookingInfo["hospital_address"] = value;
    this.setState({
      updateBookingInfo,
    });
  }

  kitSetDetailModalClose() {
    this.setState({
      kitSetDetailModal: false,
      kitSetData: [],
    });
  }

  showKitDetails(kitDetails) {
    if (this.state.kitDetailsClickFlag == 1) {
      this.setState({
        kitDetails: kitDetails,
        kitSetDetailModal: true,
        kitDetailsClickFlag: 0,
      });
    }
  }

  handleKitDetails(id) {
    this.setState({
      kitDetailsClickFlag: 1,
    });
    this.props.dispatch(setActions.getById(id));
  }

  handleTrackerChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      dispatch: {
        ...prevState.dispatch,
        tracker: value,
      },
    }));
  };

  handleSelectChange = (e) => {
    var dispatch = this.state.dispatch;
    dispatch.tracker = e.value;
    this.setState({
      dispatch: dispatch,
    });
  };

  dispatchModalOpen(id) {
    this.setState({
      dispatchModal: true,
      dispatch: {
        courier: "All Aces",
        dispatchBoxesData: [
          {
            box_type: "Black Box",
            number_of_box: "",
            numberOfBoxFlag: false,
          },
        ],
        tracker: "",
        bookingId: id,
        log_status: "dispatched",
        log_by_id: this.state.user.data._id,
        notes: "",
        dispatched_by: "Lily Rose Winters",
        other_dispatched_by: "",
      },
      boxes: [
        {
          box_type: "Black Box",
          number_of_box: "",
          numberOfBoxFlag: false,
        },
      ],
      submitDispatchFlag: 0,
      selectedDispatchedByValue: "",
      otherDispatchedFlag: false,
    });
  }

  handleDispatchCourier(val) {
    let value = val.value;
    //console.log("first,", value);
    var dispatch = this.state.dispatch;
    dispatch.courier = value;
    this.setState({
      dispatch: dispatch,
    });
    this.setState({ val });
  }

  handleBoxType(val, index) {
    var { boxes } = this.state;
    boxes[index].box_type = val.value;

    var dispatch = this.state.dispatch;
    dispatch.dispatchBoxesData = boxes;

    this.setState({
      boxes: boxes,
      dispatch: dispatch,
    });
  }

  handleDispatchedBy(val) {
    let value = val.value;
    var dispatch = this.state.dispatch;
    dispatch.dispatched_by = value;
    dispatch.other_dispatched_by = "";
    this.setState({
      dispatch: dispatch,
      otherDispatchedFlag: false,
    });
    this.setState({ selectedDispatchedByValue: value });
  }

  handleOtherDispatchedName(val) {
    var dispatch = this.state.dispatch;
    dispatch.other_dispatched_by = val;
    this.setState({
      dispatch: dispatch,
      otherDispatchedFlag: false,
    });
  }

  handleBoxNumber(val, index) {
    var { boxes } = this.state;
    if (val != "" && val < 1) {
      val = "";
    }
    boxes[index].number_of_box = val;
    boxes[index].numberOfBoxFlag = false;
    var dispatch = this.state.dispatch;
    dispatch.dispatchBoxesData = boxes;

    this.setState({
      boxes: boxes,
      dispatch: dispatch,
    });

    //console.log(this.state.dispatch);
  }

  addMoreBox() {
    var { boxes } = this.state;
    boxes.push({
      box_type: "Black Box",
      number_of_box: "",
      numberOfBoxFlag: false,
    });
    var dispatch = this.state.dispatch;
    dispatch.dispatchBoxesData = boxes;
    this.setState({
      boxes: boxes,
      dispatch: dispatch,
    });
  }

  handleBoxRemove(ind) {
    const { boxes } = this.state;
    boxes.splice(ind, 1);
    var dispatch = this.state.dispatch;
    dispatch.dispatchBoxesData = boxes;
    this.setState({
      boxes: boxes,
      dispatch: dispatch,
    });
  }

  handleDispatch() {
    this.setState({
      submitDispatchFlag: 1,
      otherDispatchedFlag: true,
    });
    var flag = 0;

    this.state.boxes.map((item) => {
      if (item.box_type == "" || item.number_of_box == "") {
        flag = 1;
      }

      if (item.number_of_box == "") {
        item.numberOfBoxFlag = true;
      }
    });

    if (
      this.state.dispatch.courier == "" ||
      this.state.dispatch.tracker == ""
    ) {
      this.props.dispatch(bookingActions.saveDispatchs(this.state.dispatch));
    } else if (flag == 0) {
      this.props.dispatch(bookingActions.saveDispatchs(this.state.dispatch));
    }
  }

  dispatchModalClose() {
    this.setState({
      dispatchModal: false,
      submitDispatchFlag: 0,
      selectedDispatchedByValue: "",
      otherDispatchedFlag: false,
    });
  }

  handleReturn() {
    this.setState({
      returnModal: true,
      returnSubmitFlag: 0,
      returnSetDataLength: 0,
    });
  }

  returnModalClose() {
    const { kitWithKitSet } = this.state;

    kitWithKitSet.map((kit) => {
      kit.sets.map((set) => {
        set.isreturn = false;
      });
    });

    this.setState({
      returnModal: false,
      returnSubmitFlag: 0,
      returnData: {
        courier: "",
        return_date: "",
        additional_note: "",
        returnBoxesData: [],
        returnSetData: [],
        bookingId: "",
        log_status: "",
        log_by_id: "",
      },
      boxArray: [{ box_type: "", number_of_box: "" }],
      kitWithKitSet: kitWithKitSet,
    });
  }

  handleCourier(val) {
    this.setState({
      returnData: {
        ...this.state.returnData,
        courier: val,
      },
    });
  }

  handleReturnSet(val, outerIndex, innerIndex) {
    const { kitWithKitSet } = this.state;
    kitWithKitSet[outerIndex].sets[innerIndex].isreturn = val;
    this.setState(
      {
        kitWithKitSet,
      },
      () => {
        this.checkDateInput();
      }
    );
  }

  checkDateInput() {
    const { kitWithKitSet } = this.state;
    var trueCount = 0;
    var setCount = 0;
    kitWithKitSet.map((kit) => {
      kit.sets.map((set) => {
        if (set.added == 1 && set.status != "close") {
          setCount++;
        }
        if (set.isreturn === true && set.status != "close" && set.added == 1) {
          trueCount++;
        }
      });
    });

    if (trueCount !== setCount) {
      this.setState({
        showDateInput: true,
      });
    } else {
      this.setState({
        showDateInput: false,
      });
    }
  }

  handleReturnDate(val) {
    this.setState({
      returnData: {
        ...this.state.returnData,
        return_date: val,
      },
    });
  }

  handleReturnBoxType(index, val) {
    const { boxArray } = this.state;
    boxArray[index].box_type = val;
    this.setState({
      boxArray,
    });
  }

  handleReturnBoxNumber(index, val) {
    const { boxArray } = this.state;
    boxArray[index].number_of_box = val;
    this.setState({
      boxArray,
    });
  }

  handleNote(val) {
    this.setState({
      returnData: {
        ...this.state.returnData,
        additional_note: val,
      },
    });
  }

  handleNotesChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      dispatch: {
        ...prevState.dispatch,
        notes: value,
      },
    }));
  };

  handleBoxAdd(e) {
    e.preventDefault();
    const { boxArray } = this.state;
    boxArray.push({ box_type: "", number_of_box: "" });
    this.setState({
      boxArray,
    });
  }

  deleteAddBox(e, index) {
    e.preventDefault();
    const { boxArray } = this.state;
    var deleteBox = boxArray;
    deleteBox.splice(index, 1);
    this.setState({
      boxArray: deleteBox,
    });
  }

  isEditable() {
    this.setState({
      isEditable: true,
    });
  }

  submitReturnData() {
    const { kitWithKitSet, returnData } = this.state;
    var falg = 0;
    var kitSetLength = 0;
    var returnSetArray = [];
    kitWithKitSet.map((kit) => {
      kit.sets.map((set) => {
        if (set.added == 1 && set.status != "close") {
          kitSetLength++;
        }
        if (set.isreturn && set.added == 1 && set.status != "close") {
          returnSetArray.push({ bookingKitSetId: set._id });
        }
      });
    });

    returnData.returnBoxesData = this.state.boxArray;
    returnData.returnSetData = returnSetArray;
    returnData.bookingId = this.props.match.params.id;
    returnData.log_status = "returned";
    returnData.log_by_id = this.state.user.data._id;

    this.state.boxArray.map((item) => {
      if (item.box_type == "" || item.number_of_box == "") {
        falg = 1;
      }
    });

    if (returnData.returnSetData.length == 0) {
      falg = 1;
    }

    if (
      returnData.returnSetData.length > 0 &&
      returnData.returnSetData.length != kitSetLength &&
      returnData.return_date == ""
    ) {
      falg = 1;
    } else if (
      returnData.return_date != "" &&
      returnData.returnSetData.length > 0 &&
      returnData.returnSetData.length == kitSetLength
    ) {
      returnData.return_date = "";
    }

    this.setState({
      returnSubmitFlag: 1,
      returnSetDataLength: returnData.returnSetData.length,
    });

    if (returnData.courier == "" || returnData.additional_note == "") {
      this.setState(
        {
          returnData,
        },
        () => {
          this.props.dispatch(bookingActions.saveReturn(this.state.returnData));
        }
      );
    } else if (falg == 0) {
      this.setState(
        {
          returnData,
        },
        () => {
          this.props.dispatch(bookingActions.saveReturn(this.state.returnData));
        }
      );
    }
  }

  logListShow(logList) {
    this.setState({
      logList: logList,
    });
  }

  redirectPage() {
    this.setState({
      redirect: true,
    });
  }

  showMoreFunction(set) {
    const { showMoreId } = this.state;
    this.setState({
      showMoreId: set?._id,
      showMore: showMoreId !== set?._id ? true : !this.state.showMore,
    });
  }

  componentDidMount() {
    this.getList();
    const timer = setTimeout(() => {
      this.getSingleBooking(this.props.match.params.id);
    }, 1500);
    return () => clearTimeout(timer);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    if (nextProps.hospitalsList !== undefined) {
      this.showHospitalsList(nextProps.hospitalsList);
    }

    if (nextProps.bookingInfo !== undefined) {
      this.setBookingData(nextProps.bookingInfo);
    }

    if (nextProps.hospitalDetails !== undefined) {
      this.showAddressList(nextProps.hospitalDetails);
    }

    if (nextProps.repsList !== undefined) {
      this.showRepsList(nextProps.repsList);
    }

    if (
      nextProps.kitListDropdown !== undefined &&
      this.state.kitListFlag == 0
    ) {
      this.showKitsList(nextProps.kitListDropdown);
    }

    if (nextProps.kitSetList !== undefined) {
      this.showKitsSetList(nextProps.kitSetList);
    }

    if (nextProps.editSet == true) {
      this.showKitDetails(nextProps.set);
    }

    if (nextProps.returnModal == true || nextProps.refreshList == true) {
      this.redirectPage();
    }

    if (nextProps.logList !== undefined) {
      this.logListShow(nextProps.logList);
    }
  }

  render() {
    const { redirect, error, loading } = this.props;
    const { options, selectedOption } = this.state;
    const { boxOptions, selectedNumOfBox } = this.state;
    const {
      dispatchedByOptions,
      selectedDispatchedByOptions,
      selectedDispatchedByValue,
      bookingReturns,
      showMore,
      showMoreId,
    } = this.state;

    // const currentDate = new Date();

    if (redirect) {
      return <Redirect to="/admin/bookings" />;
    }
    if (this.state.redirect) {
      return <Redirect to="/admin/bookings" />;
    }
    if (!this.state.user) {
      return <Redirect to="/" />;
    }
    if (this.state.user.data.user_role !== "admin") {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <Navbar activePage="bookings" />
        <div className="container">
          <div className="d-block d-sm-flex align-items-center justify-content-between">
            <h1 className="date-scroll pt-4">
              <a onClick={() => history.goBack()}>
                <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                <strong>Review Booking</strong>
              </a>
            </h1>
            {/* {this.state.bookingInfo.status == "booked" && (
              <React.Fragment>
                <div className="pt-4">
                  <Link
                    className="btn btn-primary mr-2 my-1"
                    to={
                      "/admin/booking/edit/" + this.state.updateBookingInfo.id
                    }
                  >
                    Edit
                  </Link>
                  {dateFormat(
                    this.state.bookingInfo?.working_delivery_date,
                    "yyyy-mm-dd"
                  ) <= dateFormat(currentDate, "yyyy-mm-dd") && (
                    <Button
                      onClick={() =>
                        this.dispatchModalOpen(this.state.updateBookingInfo.id)
                      }
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#BBBBBB",
                      }}
                    >
                      Dispatch
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )} */}

            {/* {this.state.bookingInfo.status != "close" &&
              this.state.bookingInfo?.bookingcollections?.length === 0 &&
              this.state.bookingInfo?.bookingdispatches?.length > 0 && (
                <React.Fragment>
                  <div className="pt-4">
                    <Link
                      className="btn btn-primary mr-2 my-1"
                      to={
                        "/admin/booking/edit/" + this.state.updateBookingInfo.id
                      }
                    >
                      Edit
                    </Link>
                  </div>
                </React.Fragment>
              )} */}
          </div>
          <div className="row pt-4 mobile_nocard">
            <div className="col-md-8">
              <div className="d-card">
                <h4 className="mb-3">Hospital Details</h4>
                <h4 className="mb-3">{this.state.bookingId}</h4>
                <h5>{this.state.hospitalName}</h5>
                <div className="form-group">
                  <div className="info">
                    <div className="info-left">
                      {this.state.hospitalAddress.length > 0 &&
                        this.state.hospitalAddress.map((item) => (
                          <div className="info-left" key={item._id}>
                            <strong> {this.state.hospitalContactPerson}</strong>
                            {console.log("object", item)}
                            <div>
                              <small>Location: {item.name}</small>
                            </div>
                            <div>
                              <small>
                                Email: {this.state.hospitalContactPersonEmail}
                              </small>
                            </div>
                            <div>
                              <small>
                                Phone: {this.state.hospitalContactPersonPhone}
                              </small>
                            </div>
                            {this.state.updateBookingInfo
                              .pickup_location_text ? (
                              <div className="mt-3">
                                <strong>Pickup Location:</strong>{" "}
                                {
                                  this.state.updateBookingInfo
                                    .pickup_location_text
                                }
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <span>
                    Surgeon Name: <strong>{this.state.surgeonName}</strong>{" "}
                  </span>
                </div>
                <div className="form-group">
                  <span>
                    Operation Name:{" "}
                    <strong>
                      {this.state.operationName == "Other"
                        ? this.state.operationOther
                        : this.state.operationName}
                    </strong>{" "}
                  </span>
                </div>
                <div className="form-group">
                  <span>
                    Bilateral Case:{" "}
                    <strong>
                      {this.state.bilateralCase ? this.state.bilateralCase : ""}
                    </strong>{" "}
                  </span>
                </div>
              </div>

              <div className="d-card mt-4">
                <h4>Purchase/Hire Details</h4>
                <div className="form-group">
                  <span>
                    PO Number:{" "}
                    <strong>
                      {this.state.isEditable ? (
                        <React.Fragment>
                          <Input
                            type="text"
                            style={{
                              width: "fit-content",
                              display: "inline-block",
                            }}
                            value={this.state.editablePoNumber}
                            onChange={(e) =>
                              this.handlePoNumber(e.target.value)
                            }
                          />
                          <span className="small ml-2">
                            <a
                              href="javascript:;"
                              onClick={() =>
                                this.updatePONumber(
                                  this.state.updateBookingInfo.id
                                )
                              }
                            >
                              Save
                            </a>
                          </span>
                        </React.Fragment>
                      ) : (
                        this.state.updateBookingInfo.po_number
                      )}
                    </strong>{" "}
                  </span>
                  {this.state.updateBookingInfo.status != "cancel" &&
                    this.state.updateBookingInfo.status != "booked" &&
                    !this.state.isEditable && (
                      <a
                        href="javascript:"
                        onClick={() => this.isEditable()}
                        className="ml-1"
                      >
                        <i className="fa fa-pencil"></i>
                      </a>
                    )}
                </div>

                <div className="mt-4">
                  <table className="table">
                    <tbody>
                      {this.state.kitWithKitSet &&
                        this.state.kitWithKitSet.map((kit, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <th colSpan="2">
                                <span className="mr-4">{kit.name}</span>
                                <span className="small">
                                  {(kit.hire_charge > 0 ||
                                    kit.quantity > 0) && (
                                    <React.Fragment>
                                      <span>
                                        Requested Quantity : {kit.quantity}
                                      </span>{" "}
                                      &nbsp;
                                      <span>
                                        Hire Charge : {kit.hire_charge}
                                      </span>
                                    </React.Fragment>
                                  )}
                                </span>
                              </th>
                            </tr>
                            {kit.sets.map((setd, ind) => (
                              <React.Fragment key={ind}>
                                {setd.added == 1 && (
                                  <tr key={ind}>
                                    <td>
                                      <div className="mb-2">
                                        <span
                                          className={
                                            "status circle mr-1 " +
                                            (setd.status == "close"
                                              ? "green "
                                              : "red ")
                                          }
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title=""
                                          data-original-title="Retuned"
                                        ></span>
                                        <a
                                          href="javascript:;"
                                          onClick={() =>
                                            this.handleKitDetails(setd._id)
                                          }
                                          data-toggle="modal"
                                          data-target="#kitDetails"
                                        >
                                          {setd.set_name}
                                        </a>
                                      </div>
                                    </td>
                                    <td>
                                      <label>Hire Charge</label>
                                      <div>{setd.hire_charge}</div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {bookingReturns?.length ? (
                <div className="d-card mt-4">
                  <h4>Returned Set Details</h4>
                  <div className="mt-4">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ opacity: 1 }}>Set Name</th>
                          <th style={{ opacity: 1 }}>Return Date </th>
                          <th style={{ opacity: 1 }}>Comment</th>
                          <th style={{ opacity: 1 }}>Box Type & Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingReturns &&
                          bookingReturns.map((sets, index) => (
                            <React.Fragment key={index}>
                              {sets.bookingreturnsets.map((set, ind) => (
                                <React.Fragment key={ind}>
                                  {set?.isSetReturn ? (
                                    <tr>
                                      <td>{set?.sets[0]?.set_name}</td>
                                      <td>
                                        {set?.set_return_date
                                          ? moment(set?.set_return_date).format(
                                              "DD-MM-YYYY"
                                            )
                                          : null}
                                      </td>
                                      <td>
                                        {set?.comment
                                          ? showMore && showMoreId === set?._id
                                            ? set?.comment
                                            : `${set.comment.substring(0, 100)}`
                                          : null}{" "}
                                        {set?.comment?.length > 100 ? (
                                          <button
                                            className="return-read-more-btn"
                                            onClick={() =>
                                              this.showMoreFunction(set)
                                            }
                                          >
                                            {showMore && showMoreId === set?._id
                                              ? "Show less"
                                              : "Show More"}
                                          </button>
                                        ) : null}
                                      </td>
                                      <td className="">
                                        {sets.bookingreturnboxes.map(
                                          (box, indx) => (
                                            <React.Fragment key={indx}>
                                              {indx === indx ? (
                                                <React.Fragment key={indx + 1}>
                                                  <div className="">
                                                    {box.box_type} {"-"}{" "}
                                                    {box.number_of_box}{" "}
                                                    {box.number_of_box > 1
                                                      ? "Boxes"
                                                      : "Box"}
                                                  </div>
                                                </React.Fragment>
                                              ) : null}
                                            </React.Fragment>
                                          )
                                        )}
                                      </td>
                                    </tr>
                                  ) : null}
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}

              <h4 className="mt-5" style={{ "font-size": "16px" }}>
                Booking Timeline
              </h4>
              <ul className="timeline mt-4 sml-text">
                {this.state.logList &&
                  this.state.logList.map((item, index) => (
                    <li key={index} data-dd={item}>
                      <strong>
                        {dateFormat(item.createdAt, "ddd,dS mmm h:MM:ss")}
                      </strong>
                      {item.status == "updated" && (
                        <p>
                          Booking information is updated by{" "}
                          {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}

                      {item.status == "pending" && (
                        <p>
                          {item.created_by.first_name}{" "}
                          {item.created_by.last_name} requested for Booking{" "}
                        </p>
                      )}

                      {item.status == "confirm" && (
                        <p>
                          Booking is confirmed by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}
                      {item.status == "prepare" && (
                        <p>
                          Booking is prepared by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}
                      {item.status == "booked" && (
                        <p>
                          Booking added by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}

                      {item.status == "returned" && (
                        <p>
                          Booking returned by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}

                      {item.status == "rejected" && (
                        <p>
                          Booking rejected by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}

                      {item.status == "dispatched" && (
                        <p>
                          Booking dispatched by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}

                      {item.status == "collected" && (
                        <p>
                          Booking collected by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}

                      {item.status == "cancel" && (
                        <p>
                          Booking cancelled by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}

                      {item.status == "repsUpdate" && (
                        <p>
                          Booking reps updated by {item.created_by.first_name}{" "}
                          {item.created_by.last_name}
                        </p>
                      )}
                    </li>
                  ))}
              </ul>
            </div>

            <div className="col-md-4">
              <div className="d-card">
                <h4>Important Dates</h4>
                {this.state.updateBookingInfo.booking_type == "loan" && (
                  <div className="form-group">
                    <label>Delivery date</label>
                    <div>
                      <strong>
                        {dateFormat(
                          this.state.updateBookingInfo.delivery_date,
                          "ddd,mmm dS"
                        )}{" "}
                        {this.state.updateBookingInfo.delivery_date_slot}
                      </strong>
                    </div>
                  </div>
                )}
                {this.state.updateBookingInfo.booking_type != "loan" && (
                  <div className="form-group">
                    <label>Delivery date</label>
                    <div>
                      <strong>
                        {dateFormat(
                          this.state.updateBookingInfo.delivery_date,
                          "ddd,mmm dS"
                        )}{" "}
                      </strong>
                    </div>
                  </div>
                )}
                {this.state.updateBookingInfo.booking_type == "loan" && (
                  <div className="form-group">
                    <label>Operation date</label>
                    <div>
                      <strong>
                        {dateFormat(
                          this.state.updateBookingInfo.operation_date,
                          "ddd,mmm dS"
                        )}{" "}
                        {this.state.updateBookingInfo.operation_date_slot}
                      </strong>
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <label>Collection date</label>
                  <div>
                    <strong>
                      {dateFormat(
                        this.state.updateBookingInfo.collection_date,
                        "ddd,mmm dS"
                      )}{" "}
                      AM
                    </strong>
                  </div>
                </div>
              </div>
              <div className="d-card mt-4">
                <h4>Charges</h4>
                <div className="form-group">
                  <label>Shipping Charge</label>
                  <strong>
                    {" "}
                    £ {this.state.updateBookingInfo.shipping_charge}
                  </strong>
                </div>
              </div>

              <div className="d-card mt-4">
                <h4>Booked By</h4>
                <div className="form-group">
                  <div>
                    <span> {this.state.repName}</span>
                  </div>
                  <div>{this.state.repEmail}</div>
                </div>
              </div>

              <div className="d-card mt-4">
                <h4>Additional Reps</h4>
                <div className="form-group">
                  {/* <div><strong> {this.state.repName}</strong></div> */}
                  {this.state.additionalRepsNames?.length
                    ? this.state.additionalRepsNames.map((item, i) => {
                        return (
                          <div key={i}>
                            <span> {item}</span>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>

              <div className="d-card my-4">
                <h4>Additional Notes</h4>
                <div className="form-group">
                  <small>{this.state.updateBookingInfo.additional_notes}</small>
                </div>
              </div>
            </div>
          </div>

          {/* ADD FORM */}
        </div>

        {/* dispatch Details Modal */}

        <Modal
          size="lg"
          backdrop="static"
          isOpen={this.state.kitSetDetailModal}
          toggle={() => this.kitSetDetailModalClose()}
        >
          <ModalHeader
            className=""
            toggle={() => this.kitSetDetailModalClose()}
          >
            Set Details
          </ModalHeader>
          <ModalBody>
            <table className="table">
              <thead>
                <tr>
                  <th>Item Code</th>
                  <th>Item Name</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {this.state.kitDetails.setProductModules &&
                  this.state.kitDetails.setProductModules.map(
                    (product, ind) => (
                      <tr key={ind}>
                        <td>{product.productID.code}</td>
                        <td>{product.productID.name}</td>
                        <td>{product.quantity}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <div className="">
              <button
                className="btn btn-secondary"
                onClick={() => this.kitSetDetailModalClose()}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </Modal>

        {/* Set Details Modal */}

        <Modal
          size="md"
          backdrop="static"
          isOpen={this.state.returnModal}
          toggle={() => this.returnModalClose()}
        >
          <ModalHeader charcode="Y" toggle={() => this.returnModalClose()}>
            Add Return
          </ModalHeader>
          <ModalBody>
            <form className="">
              <div className="form-group">
                <label>Courier</label>
                <Input
                  type="text"
                  className="form-control"
                  value={this.state.returnData.courier}
                  onChange={(e) => this.handleCourier(e.target.value)}
                />
                {this.state.returnSubmitFlag == 1 && error ? (
                  <span className="text-danger">
                    {error.courier ? error.courier : ""}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <label>Select the returned Sets</label>
                {this.state.kitWithKitSet &&
                  this.state.kitWithKitSet.map((kit, index) => (
                    <React.Fragment key={index}>
                      {kit.sets.map((setd, ind) => (
                        <React.Fragment key={ind}>
                          {setd.added == 1 && setd.status != "close" && (
                            <div className="form-check" key={ind}>
                              {/* <Input 
                                                            type="radio" 
                                                            id={item._id}
                                                            name="hospitalAddress" 
                                                            value={item.name} 
                                                            checked={item.checked==true}
                                                            onChange={(e) => this.handleBookingAddress(e.target.value,e.target.checked,ind)}
                                                        /> */}
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                checked={setd.isreturn}
                                onChange={(e) =>
                                  this.handleReturnSet(
                                    e.target.checked,
                                    index,
                                    ind
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="return_set"
                              >
                                {setd.set_name}
                              </label>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                {this.state.returnSubmitFlag == 1 &&
                this.state.returnSetDataLength == 0 ? (
                  <span className="text-danger">
                    Please check at least one item
                  </span>
                ) : (
                  ""
                )}
              </div>
              <label>Number of Boxes</label>
              {this.state.boxArray.map((boxItem, index) => (
                <div className="form-group row align-items-top" key={index}>
                  <div className="col-5">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Box Type"
                      onChange={(e) =>
                        this.handleReturnBoxType(index, e.target.value)
                      }
                      value={boxItem.box_type}
                    />
                    {boxItem.box_type == "" &&
                    this.state.returnSubmitFlag == 1 ? (
                      <span className="text-danger">Please enter box type</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-6">
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="Number of Boxes"
                      min="0"
                      onChange={(e) =>
                        this.handleReturnBoxNumber(index, e.target.value)
                      }
                      value={boxItem.number_of_box}
                    />
                    {boxItem.number_of_box == "" &&
                    this.state.returnSubmitFlag == 1 ? (
                      <span className="text-danger">
                        Please enter number of boxes{" "}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {index > 0 && (
                    <div className="col-1 text-center">
                      <a
                        className="ml-2"
                        onClick={(e) => this.deleteAddBox(e, index)}
                        href="#"
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </div>
                  )}
                </div>
              ))}
              <a
                href="#!"
                className="sml-btn"
                onClick={(e) => this.handleBoxAdd(e)}
              >
                <i className="fa fa-plus"></i> Add More
              </a>
              {this.state.showDateInput && (
                <div className="form-group mt-4">
                  <label>
                    Please specify the new return date in case of no return or
                    partial return
                  </label>
                  <Input
                    type="date"
                    value={this.state.returnData.return_date}
                    onChange={(e) => this.handleReturnDate(e.target.value)}
                    className="small"
                  />
                  {this.state.returnSubmitFlag == 1 &&
                  this.state.returnData.return_date == "" ? (
                    <span className="text-danger">
                      Please pick date for next return
                    </span>
                  ) : (
                    ""
                  )}

                  {this.state.returnSubmitFlag == 1 && error ? (
                    <span className="text-danger">
                      {error.return_date ? error.return_date : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="form-group mt-4">
                <label>Add a Note</label>
                <textarea
                  className="form-control"
                  rows="3"
                  value={this.state.returnData.additional_note}
                  onChange={(e) => this.handleNote(e.target.value)}
                ></textarea>
                {this.state.returnSubmitFlag == 1 && error ? (
                  <span className="text-danger">
                    {error.additional_note ? error.additional_note : ""}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <div className="">
              <button
                className="btn btn-secondary mr-2"
                onClick={this.returnModalClose}
              >
                Cancel
              </button>
              {loading == false && (
                <button
                  className="btn btn-primary"
                  onClick={this.submitReturnData}
                >
                  Submit
                </button>
              )}
              {loading == true && (
                <button className="btn btn-primary loader-icon" disabled>
                  <Spinner className="img-svg"></Spinner>
                </button>
              )}
            </div>
          </ModalFooter>
        </Modal>
        {/* Return Details Modal */}

        <Modal
          size="md"
          backdrop="static"
          isOpen={this.state.dispatchModal}
          toggle={() => this.dispatchModalClose()}
        >
          <ModalHeader className="" toggle={() => this.dispatchModalClose()}>
            Add Dispatch
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>Courier</label>

              <Select
                defaultValue={selectedOption}
                // onChange={(e) => this.handleCourier(e)}
                onChange={(e) => this.handleDispatchCourier(e)}
                options={options}
              />
              {this.state.submitDispatchFlag == 1 && error ? (
                <span className="text-danger">
                  {error.courier ? error.courier : ""}
                </span>
              ) : (
                ""
              )}
            </div>

            <div
              className="form-group position_up"
              style={{ zIndex: this.state.boxes.length + 1 }}
            >
              <label>Dispatched By</label>
              <Select
                defaultValue={selectedDispatchedByOptions}
                onChange={(e) => this.handleDispatchedBy(e)}
                options={dispatchedByOptions}
              />
              {error && this.state.submitDispatchFlag == 1 ? (
                <span className="text-danger">
                  {error.dispatched_by ? error.dispatched_by : ""}
                </span>
              ) : (
                ""
              )}
            </div>

            {selectedDispatchedByValue === "Other" ? (
              <div className="row">
                <div className="col-sm-12 mb-3">
                  <Input
                    type="text"
                    value={this.state?.dispatch?.other_dispatched_by}
                    onChange={(e) =>
                      this.handleOtherDispatchedName(e.target.value)
                    }
                    className="form-control"
                    name=""
                    placeholder="Other Dispatched Name"
                  />
                  {this.state?.dispatch?.other_dispatched_by?.trim() == "" &&
                  this.state.otherDispatchedFlag == true ? (
                    <span className="text-danger">
                      Please Other Dispatched Name
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="form-group">
              <label>Number of Boxes</label>
              {this.state.boxes &&
                this.state.boxes.map((item, index) => (
                  <div
                    className="form-group row position_up"
                    style={{
                      zIndex: this.state.boxes.length - index,
                    }}
                    key={index}
                  >
                    <div className="col-sm-5 mb-3">
                      <Select
                        defaultValue={selectedNumOfBox}
                        onChange={(e) => this.handleBoxType(e, index)}
                        options={boxOptions}
                        placeholder="Box Type"
                      />
                      {item.box_type == "" &&
                      this.state.submitDispatchFlag == 1 ? (
                        <span className="text-danger">
                          Please enter box type
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-sm-6 mb-3">
                      <Input
                        type="number"
                        value={item.number_of_box}
                        onChange={(e) =>
                          this.handleBoxNumber(e.target.value, index)
                        }
                        className="form-control"
                        name=""
                        placeholder="Number of Boxes"
                      />
                      {item.number_of_box == "" &&
                      this.state.submitDispatchFlag == 1 &&
                      item?.numberOfBoxFlag ? (
                        <span className="text-danger">
                          Please enter number of boxes
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="">
                      {index != 0 && (
                        <i
                          className="fa fa-trash"
                          onClick={() => this.handleBoxRemove(index)}
                        ></i>
                      )}
                    </div>
                  </div>
                ))}
              <a
                href="javascript:;"
                className="sml-btn"
                onClick={() => this.addMoreBox()}
              >
                <i className="fa fa-plus"></i> Add More
              </a>
            </div>

            <div className="form-group">
              <label>GPS Tracker</label>
              <Input
                type="text"
                className="form-control"
                name=""
                onChange={this.handleTrackerChange}
              />
              {error && this.state.submitDispatchFlag == 1 ? (
                <span className="text-danger">
                  {error.tracker ? error.tracker : ""}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>Notes</label>
              <Input
                type="textarea"
                className="form-control"
                name=""
                onChange={this.handleNotesChange}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="">
              <button
                onClick={() => this.dispatchModalClose()}
                className="btn btn-secondary mr-2"
                data-dismiss="modal"
              >
                Cancel
              </button>
              {loading == false && (
                <button
                  className="btn btn-primary"
                  onClick={() => this.handleDispatch()}
                >
                  Ok
                </button>
              )}
              {loading == true && (
                <button className="btn btn-primary loader-icon" disabled>
                  <Spinner className="img-svg"></Spinner>
                </button>
              )}
            </div>
          </ModalFooter>
        </Modal>

        {/* Dispatch Modal */}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    bookingInfo,
    hospitalDetails,
    hospitalsList,
    repsList,
    error,
    redirect,
    kitSetList,
    returnModal,
    logList,
    refreshList,
    loading,
  } = state.rootReducer.bookings;
  const { kitListDropdown } = state.rootReducer.kits;
  const { editSet, set } = state.rootReducer.sets;
  return {
    bookingInfo,
    hospitalDetails,
    hospitalsList,
    repsList,
    error,
    redirect,
    kitSetList,
    returnModal,
    kitListDropdown,
    editSet,
    set,
    logList,
    refreshList,
    loading,
  };
}

export default connect(mapStateToProps)(Details);
