import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../Navbar";
import { Redirect, Link } from "react-router-dom";
import { bookingService } from "../../../../services";
import dateFormat from "dateformat";
import DispatchModal from "./Modal/dispatchModal";
import AcceptModal from "./Modal/acceptModal";
import RejectModal from "./Modal/rejectModal";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import CloseModal from "./Modal/closeModal";
import NavbarComponent from "./Navbar/Index";
import { ExportSheet } from "react-xlsx-sheet";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import moment from "moment";
import { consignmentService } from "../../../../services/consignment.services";

const PendingConsignmentRequest = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [bookings, setBookings] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [dispatchModal, setDispatchModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataXlsx, setDataXlsx] = useState([]);
  const head = [
    { title: "Consignment Id", dataIndex: "Consignment Id" },
    { title: "Hospital Name", dataIndex: "Hospital Name" },
    { title: "Contact Person", dataIndex: "Contact Person" },
    { title: "Surgeon Name", dataIndex: "Surgeon Name" },
    { title: "Kits", dataIndex: "Kits" },
    { title: "Delivery Date", dataIndex: "Delivery Date" },
    { title: "Notes", dataIndex: "Notes" },
    { title: "Booked By", dataIndex: "Booked By" },
  ];

  const getXlsxData = async () => {
    setIsLoading(true);
    try {
      const res = await consignmentService.getConsignmentsExport({
        ...searchParams,
        page: 1,
        limit: "",
      });
      if (res.status) {
        showXlsData(res?.data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  async function showXlsData(data) {
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("Open Consignment Report");
    const header1 = [
      "CONSIGNMENT ID",
      "HOSPITAL NAME",
      "CONTACT PERSON",
      "SURGEON NAME",
      "KITS",
      "DELIVERY DATE",
      "NOTES",
      "BOOKED BY",
    ];
    const header1Row = ws.addRow(header1);
    header1Row.eachCell((cell) => {
      cell.font = {
        bold: true,
        size: 11,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCC0DA" }, 
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

  
    if (data?.length) {
      console.log(";:::", data);
      let kitset = [];
      let rowIndex = 1;
      data &&
        data.map((item) => {
          console.log("item", item);
          const surgeonNames = [];
          kitset = [];
          item.consignmentKits &&
            item.consignmentKits.map((kset) => {
              if (kitset.length) {
                kitset.push(kset?.kits[0]?.name);
              } else {
                kitset.push(kset?.kits[0]?.name);
              }
            });
          console.log("kitset", kitset); // Collect surgeon names
          item.surgeonData?.forEach((surgeon) => {
            const fullName = `${surgeon?.first_name} ${surgeon?.last_name}`;
            if (fullName && !surgeonNames.includes(fullName)) {
              surgeonNames.push(fullName);
            }
          });
          const surgeonsToAdd = surgeonNames.length > 0 ? surgeonNames : [""];

          const rowData = [
                 item?.consignments?.consignment_id,
            item?.hospitalData[0]?.name,
            item?.hospitalData[0]?.contact_person_name,
            surgeonsToAdd.join(", "), // Combine all surgeons into one cell
            item.consignments?.delivery_date? moment(item.consignments?.delivery_date).format("ddd, MMMM Do, YYYY") : "",
            item?.consignments?.note,
            item?.createdData[0]?.first_name && 
            item?.createdData[0]?.last_name ? item?.createdData[0]?.first_name +
            " " +
            item?.createdData[0]?.last_name:"",
    ];

          const setsArray = kitset;
          console.log("LLL", kitset, setsArray);
          if (setsArray?.length) {
            for (let i = 0; i < setsArray.length; i++) {
              if (i == 0) {
                const mainDataRow = [
                  ...rowData.slice(0, 4),
                  setsArray[i],
                  ...rowData.slice(4, 7),
                ];
                ws.addRow(mainDataRow);
                rowIndex += 1;
              } else {
                const setRow = ["", "", "","", setsArray[i], "", "", ""]; // Empty values for name, address, and age, set value for sets column on subsequent rows of sets
                ws.addRow(setRow);

                if (setsArray.length - 1 === i) {
                  // Merge cells in the name, address, and age columns for the first row of each group of sets
                  if (setsArray.length > 1) {
                    ws.mergeCells(
                      rowIndex,
                      1,
                      rowIndex + setsArray.length - 1,
                      1
                    ); // Merge cells in the name column
                    ws.mergeCells(
                      rowIndex,
                      2,
                      rowIndex + setsArray.length - 1,
                      2
                    ); // Merge cells in the address column
                    ws.mergeCells(
                      rowIndex,
                      3,
                      rowIndex + setsArray.length - 1,
                      3
                    ); // Merge cells in the age column
                    ws.mergeCells(
                      rowIndex,
                      4,
                      rowIndex + setsArray.length - 1,
                      4
                    ); // Merge cells in the age column
                    ws.mergeCells(
                      rowIndex,
                      6,
                      rowIndex + setsArray.length - 1,
                      6
                    ); // Merge cells in the age column
                    ws.mergeCells(
                      rowIndex,
                      7,
                      rowIndex + setsArray.length - 1,
                      7
                    ); 
                    ws.mergeCells(
                      rowIndex,
                      8,
                      rowIndex + setsArray.length - 1,
                      8
                    );
                  }
                  rowIndex += setsArray.length ? setsArray.length - 1 : 1; // Increment rowIndex for the next group of sets
                }
              }
            }
          } else {
            const mainDataRow = [ ...rowData.slice(0, 4),
              ...rowData.slice(4, 7)];
            ws.addRow(mainDataRow);
            rowIndex += 1;
          }
        });
    }
    ws.columns.forEach((col) => (col.width = 18));

    // Iterate through each column to calculate and set width
    ws.columns.forEach((column, columnIndex) => {
      let maxLength = 30;
      // Start from the third row (index 2) to skip the header row
      for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
        const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;

        if (cellValue && cellValue.toString().length > maxLength) {
          maxLength = 40;
        }
      }
      // Set the width of the column based on the maximum length of its content
      column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
    });

    ws.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          };
        });  
      }
    });
    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

  
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "Open Consignment Report.xlsx";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
    setIsLoading(false);
  }
  
 
  
  const fetchBookings = async (searchParams, page = 1) => {
    try {
      setLoading(true);
      const res = await bookingService.getConsignmentRequest({
        ...searchParams,
        page,
      });
      const newBookings = res.data;

      setBookings((prevBookings) => {
        return page === 1 ? newBookings : [...prevBookings, ...newBookings];
      });
      setHasMore(newBookings.length > 0);
      setLoading(false);
      if (res.status) {
        setTotalItemsCount(res?.total_count);
      }
    } catch (error) {
      console.error("Error: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    if (Object.keys(searchParams).length > 0) {
      fetchBookings(searchParams, 1);
    }
  }, [searchParams]);

  useEffect(() => {
    console.log("Search Params updated: ", searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (page > 1) {
      fetchBookings(searchParams, page);
    }
  }, [page]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, loading]);

  useEffect(() => {
    console.log("searhc", searchParams);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const dispatchModalOpen = (id) => {
    setDispatchModal(true);
    setBookingId(id);
  };

  const closeModalOpen = (id) => {
    setCloseModal(true);
    setBookingId(id);
  };

  const closeModalClose = () => {
    setCloseModal(false);
    setBookingId(null);
  };
  const dispatchModalClose = () => {
    setDispatchModal(false);
    setBookingId(null);
  };

  const acceptModalOpen = (id, deliveryDate) => {
    setAcceptModal(true);
    setBookingId(id);
    setDeliveryDate(deliveryDate);
  };

  const acceptModalClose = () => {
    setAcceptModal(false);
    setBookingId(null);
  };
  console.log(setLoading, setDataXlsx);
  const rejectModalOpen = (id) => {
    setRejectModal(true);
    setBookingId(id);
  };

  const rejectModalClose = () => {
    setRejectModal(false);
    setBookingId(null);
  };

  const handleReload = () => {
    setPage(1);
    fetchBookings(searchParams, 1);
  };

  if (!user) {
    return <Redirect to="/" />;
  }

  if (user.data.user_role !== "admin") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Navbar activePage="consignment-request" />
      <div className="container-fluid">
        <div className="d-flex" style={{ alignItems: "center" }}>
          <NavbarComponent setSearchParams={setSearchParams} />
          <div>
            <button
    style={{ marginTop: "40px" }}
    className={
      !loading && !isLoading
            ? "btn btn-default add_print ml-3"
            : "btn btn-default add_print ml-3 dropdown-disabled"
    }
    onClick={() =>
         !loading && !isLoading && bookings?.length ? getXlsxData() : ""
    }
    id="ReportDownload"
    // title="Print" // Tooltip text
>
    <i className="fa fa-print"></i>
</button>
<UncontrolledTooltip target={"ReportDownload"}>
              Print
            </UncontrolledTooltip>
          </div>
          {!loading && !isLoading ? (
            ""
          ) : (
            <UncontrolledTooltip target={"ReportDownload"}>
              {loading
                ? "Please wait"
                : isLoading
                ? "Please wait"
                : "No Record Found"}
            </UncontrolledTooltip>
          )}

          {dataXlsx && (
            <ExportSheet
              header={head}
              fileName={"bookings"}
              dataSource={dataXlsx}
              xlsx={XLSX}
            >
              <span></span>
            </ExportSheet>
          )}
        </div>
        <div
          className="small mr-3 badge badge-info"
          style={{
            fontWeight: 500,
            background: "#5d75e9",
            marginBottom: 0,
          }}
        >
          {totalItemsCount} Records found
        </div>
        <div id="table-scroll" className="table-scroll mt-2">
          <table id="main-table" className="main-table">
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "250px" }}>
                  Hospital Name
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Contact Person
                </th>
                <th scope="col" style={{ minWidth: "180px" }}>
                  Surgeon Name
                </th>
                <th scope="col">Kits</th>
                <th scope="col">Delivery Date</th>
                <th scope="col">Booked By</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking) => {
                  const { _id: consignmentId, delivery_date } =
                    booking.consignments;
                  return (
                    <tr key={consignmentId}>
                      <td data-th="Hospital Name">
                        <div>
                          <Link
                            to={
                              "/admin/consignment-request/details/" +
                              booking.consignments._id
                            }
                          >
                            <span>
                              <strong>
                                <span
                                  style={{ display: "block" }}
                                  className="theme-text"
                                >
                                  {booking.consignments.consignment_id}
                                </span>
                                {booking.hospitalData[0]?.name}
                              </strong>
                            </span>
                            <br />
                            <small>
                              Booked on{" "}
                              {dateFormat(
                                booking.consignments.createdAt,
                                "ddd, mmm dS"
                              )}
                            </small>
                          </Link>
                        </div>
                      </td>
                      <td data-th="Contact Person">
                        <div className="info-left">
                          <strong>
                            {booking.hospitalData[0]?.contact_person_name}{" "}
                          </strong>
                          <br />
                          <small>
                            Location: {booking.consignments.hospital_address}{" "}
                          </small>
                          <br />
                          <small>Email: {booking.hospitalData[0]?.email}</small>
                          <br />
                          <small>
                            Phone:{" "}
                            {
                              booking.hospitalData[0]
                                ?.contact_person_phone_number
                            }
                          </small>
                          <br />
                          <small>
                            Department:{" "}
                            {booking.consignments.hospital_department}
                          </small>
                        </div>
                      </td>
                      <td data-th="Surgeon Name">
                        <div className="info-left">
                          {booking.surgeonData.length > 0
                            ? booking.surgeonData.map((surgeon) => (
                                <div key={surgeon._id}>
                                 
                                    {surgeon.title} {surgeon.first_name}{" "}
                                    {surgeon.last_name}
                                 
                                  <br />
                                </div>
                              ))
                            : "Not Assigned"}
                        </div>
                      </td>
                      <td data-th="Kits">
                        <small>
                          {booking.consignmentKits.length > 0
                            ? booking.consignmentKits.map((kit) => (
                                <a
                                  key={kit._id}
                                  href="javascript:;"
                                  data-toggle="modal"
                                  data-target="#kitDetails"
                                >
                                  {kit.kits[0]?.name} <br />
                                </a>
                              ))
                            : "None"}
                        </small>
                      </td>
                      <td data-th="Delivery Date">
                        {booking?.consignments?.delivery_date
                          ? dateFormat(
                              booking?.consignments?.delivery_date,
                              "ddd, mmm dS"
                            )
                          : ""}
                      </td>
                      <td data-th="Booked By">
                        <div className="info-left">
                          {booking.createdData[0]?.first_name}{" "}
                          {booking.createdData[0]?.last_name}
                        </div>
                      </td>
                      <td data-th="Actions">
                        <div className="action-area dropdown">
                          <div className="mb-2">
                            <span
                              className={
                                "capsule " +
                                (booking.consignments.status == "booked" ||
                                booking.consignments.status === "close" ||
                                booking.consignments.status == "dispatch" ||
                                booking.consignments.status ===
                                  "partial-dispatch"
                                  ? "green-dim "
                                  : "red-dim ") +
                                "mb-1"
                              }
                            >
                              {booking.consignments.status === "booked"
                                ? "Reviewed and Accepted"
                                : booking.consignments.status === "pending"
                                ? "Not Reviewed"
                                : booking.consignments.status == "dispatch"
                                ? "Dispatched"
                                : booking.consignments.status ===
                                  "partial-dispatch"
                                ? "Partially Dispatched"
                                : "booking.consignments.status"}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex">
                          {booking.consignments.status === "pending" && (
                            <>
                              <div className="mb-2">
                                <span>
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      acceptModalOpen(
                                        consignmentId,
                                        delivery_date
                                      )
                                    }
                                    className="btn btn-outline-primary btn-sm1"
                                  >
                                    Accept
                                  </a>
                                </span>
                              </div>
                              <div className="mb-2">
                                <span>
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      rejectModalOpen(booking.consignments._id)
                                    }
                                    className="btn btn-outline-primary btn-sm1"
                                  >
                                    Reject
                                  </a>
                                </span>
                              </div>
                            </>
                          )}

                          {(booking.consignments.status === "booked" ||
                            booking.consignments.status ===
                              "partial-dispatch") && (
                            <div className="mb-2">
                              <span>
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    dispatchModalOpen(booking.consignments._id)
                                  }
                                  className="btn btn-outline-primary btn-sm1"
                                >
                                  Dispatch
                                </a>
                              </span>
                            </div>
                          )}
                          {booking.consignments.status === "dispatch" && (
                            <div className="mb-2">
                              <span>
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    closeModalOpen(booking.consignments._id)
                                  }
                                  className="btn btn-outline-primary btn-sm1"
                                >
                                  Close
                                </a>
                              </span>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No bookings found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {loading && (
            <div className="text-center my-4">
              <Spinner color="primary" />
            </div>
          )}
        </div>
      </div>
      <DispatchModal
        isOpen={dispatchModal}
        bookingId={bookingId}
        toggle={dispatchModalClose}
        onReload={handleReload}
      />
      <CloseModal
        isOpen={closeModal}
        bookingId={bookingId}
        toggle={closeModalClose}
        onReload={handleReload}
      />
      <AcceptModal
        isOpen={acceptModal}
        bookingId={bookingId}
        deliveryDate={deliveryDate}
        toggle={acceptModalClose}
        onReload={handleReload}
      />
      <RejectModal
        isOpen={rejectModal}
        bookingId={bookingId}
        toggle={rejectModalClose}
        onReload={handleReload}
      />
    </div>
  );
};

export default PendingConsignmentRequest;
