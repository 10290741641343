import jsPDF from "jspdf";
import "jspdf-autotable";
import * as excelJs from "exceljs";
import moment from "moment";

export const downloadFile = {
    handleExportClick,
    handleGeneratePdf
};

async function handleExportClick(data,headingDiscription){
    const workbook = new excelJs.Workbook();
    const ws = workbook.addWorksheet("Consignment Report");
    
    let headingContent =  "Dispatch "+ headingDiscription;
    // Add a header row with a merged cell for the heading
    const headingRow = ws.addRow([headingContent]);
    headingRow.getCell(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
    };

    headingRow.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 13,
        };
    });

    //ws.mergeCells(dayActive === "past" ? "A1:D1" : "A1:B1"); // Merge cells for the heading
    ws.mergeCells("A1:D1");

    const header1 = [
        "HOSPITAL NAME",
        "BOOKING ID",
        "PRIORITY",
        "BOOKING TYPE",
        "DELIVERY DATE",
        "SET",
        "CHECKED",
        "BOXED",
    ];
    const header1Row = ws.addRow(header1);
    header1Row.eachCell((cell) => {
        cell.font = {
            bold: true,
            size: 11,
        };
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
        };
        cell.border = {
            top: {style:"thin"},
            left: {style:"thin"},
            bottom: {style:"thin"},
            right: {style:"thin"}
        };
        cell.alignment = {
            vertical: "middle", horizontal: "center",
        };
    });

    if (data?.length) {
        //let kitset = "";
        let kitset = [];
        let rowIndex = 2; // Start from row index 2 (after the header row)
        data && data.map(item => {
            console.log("ayusjhi",item);
            kitset = [];
            item.bookingKitSetId &&
                item.bookingKitSetId.map(kset => {
                    console.log("kset",kset);
                    if (kitset.length) {
                        //kitset += "\n" + kset.setId[0].set_name;
                        kitset.push(kset?.setId[0]?.set_name);
                    } else {
                        //kitset += kset.setId[0].set_name;
                        kitset.push(kset?.setId[0]?.set_name);
                    }
            });

            const rowData = [
                item.hospitalId.name,
                item?.booking_id,
                item?.delivery_date_slot,
                item?.booking_type.charAt(0)?.toUpperCase() + item?.booking_type.slice(1),
                moment(item.delivery_date).format("ddd, MMMM Do, YYYY"),
                kitset,
                "",
                ""
            ];
            
            const setsArray = kitset;

            if(setsArray?.length){
                for (let i = 0; i < setsArray.length; i++) {
                    if (i == 0) {
                      const mainDataRow = [...rowData.slice(0, 5), setsArray[i],"",""];
                      ws.addRow(mainDataRow);
                      rowIndex += 1;
                    } else {
                      const setRow = ["", "", "","","",setsArray[i],"",""]; // Empty values for name, address, and age, set value for sets column on subsequent rows of sets
                      ws.addRow(setRow);

                      if(setsArray.length -1 === i){
                        // Merge cells in the name, address, and age columns for the first row of each group of sets
                        if (setsArray.length > 1) {
                            ws.mergeCells(rowIndex, 1, rowIndex + setsArray.length - 1, 1); // Merge cells in the name column
                            ws.mergeCells(rowIndex, 2, rowIndex + setsArray.length - 1, 2); // Merge cells in the address column
                            ws.mergeCells(rowIndex, 3, rowIndex + setsArray.length - 1, 3); // Merge cells in the age column
                            ws.mergeCells(rowIndex, 4, rowIndex + setsArray.length - 1, 4); // Merge cells in the age column
                            ws.mergeCells(rowIndex, 5, rowIndex + setsArray.length - 1, 5); // Merge cells in the age column
                        }
                        rowIndex += setsArray.length ? setsArray.length - 1 : 1; // Increment rowIndex for the next group of sets
                      }
                    }
                }
            }else{
                const mainDataRow = [...rowData.slice(0, 5),"","",""];
                ws.addRow(mainDataRow);
                rowIndex += 1;
            }
        });
    }
    ws.columns.forEach((col) => (col.width = 18));

    // Iterate through each column to calculate and set width
    ws.columns.forEach((column, columnIndex) => {
        let maxLength = 0;
        // Start from the third row (index 2) to skip the header row
        for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
            const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;

            if (cellValue && cellValue.toString().length > maxLength) {
                maxLength = cellValue.toString().length;
            }
        }
        // Set the width of the column based on the maximum length of its content
        column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
    });

    ws.eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } },
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "left",
                };
            });
        }
    });

    // setIsLoading(false);

    const excelBlob = await workbook.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
        new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
    );

    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "booking-dispatch";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);  
}

const formatSets = (sets) => {
    // Join set names with line breaks
    return sets.map(set => `${set}`).join("\n");
};

const formatLines = (lines) => {
    // Join line names with line breaks
    return lines.map(line => `${line}`).join("\n");
};

async function handleGeneratePdf(item,data,headingDiscription) {
    // const { bookingList } = this.props;
    let mainItem = data?.length ? data.length : 0;
    const pdfData = [];
    
    if(item?.booking_id){
        let obj = {};
        let setArray = [];
        let checkedLineArray = [];
        obj.booking_id = item?.booking_id;
        obj.reps_name = item?.assigned_rep?.first_name + " " + item?.assigned_rep?.last_name;
        obj.hospital_name = item?.hospitalId?.name;
        obj.checkout = "________________";
        item.bookingKitSetId &&
            item.bookingKitSetId.map((kset) => {
                setArray.push(kset.setId[0].set_name);
                checkedLineArray.push("________________");
            });
        obj.sets = setArray;
        obj.checkLines = checkedLineArray;
        pdfData.push(obj);
    }else{
        mainItem ? data.map((item) => {
            let obj = {};
            let setArray = [];
            let checkedLineArray = [];
            obj.booking_id = item?.booking_id;
            obj.reps_name = item?.assigned_rep?.first_name + " " + item?.assigned_rep?.last_name;
            obj.hospital_name = item?.hospitalId?.name;
            obj.checkout = "________________";
            item.bookingKitSetId &&
                item.bookingKitSetId.map((kset) => {
                    setArray.push(kset?.setId[0]?.set_name);
                    checkedLineArray.push("________________");
                });
            obj.sets = setArray;
            obj.checkLines = checkedLineArray;
            pdfData.push(obj);
            //return obj;
        }) : [];
    }

    let headingContent =  ["Dispatch "+ headingDiscription];

    const pdfDoc = new jsPDF();

    const columns = ["BOOKING", "REPS NAME", "HOSPITAL NAME", "SET", "CHECKED"];

    pdfData.forEach((record, index) => {
        if (index > 0) {
            pdfDoc.addPage(); // Add a new page for each record after the first one
            // pdfDoc.setFontSize(14);
            // pdfDoc.text( 14, 10,headingContent);
        }else{
            // pdfDoc.setFontSize(14);
            // pdfDoc.text( 14, 10,headingContent);
        }

        const header = columns.map((col) => ({ title: col, dataKey: col.toLowerCase() }));
        const body = [[record.booking_id, record.reps_name, record.hospital_name, formatSets(record.sets), formatLines(record.checkLines)]];

        pdfDoc.autoTable({
            head: [headingContent],
        });

        pdfDoc.autoTable({
            head: [header], body, didDrawPage: didDrawPageCallback, columnStyles: {
                0: { halign: "center" },
                1: { halign: "center" },
                2: { halign: "center" },
                3: { halign: "center", overflow: "ellipsize", minCellWidth: 80 },
                4: { halign: "center" }
            },
            headStyles: {
                halign: "center"
            },
            margin: { top: 0 }
        });
    });
    //setIsLoading(false);
    pdfDoc.save(item?.booking_id ? item?.booking_id+"-booking-dispatch.pdf" : "booking-dispatch.pdf");
}

const didDrawPageCallback = (data) => {
    // Draw a border around the table on each page
    const { doc, settings } = data;
    const startX = settings.margin.left;
    const startY = settings.margin.top+29;
    const columnWidths = data.table.columns.map((col) => col.width);
    const rowHeight = data.table.body[0].height;
    const headHeight = data.table.head[0].height;
    doc.setDrawColor(0); // Set the border color to black
    doc.setLineWidth(0.2); // Set the border line width

    // Draw borders for each column
    let currentX = startX;
    columnWidths.forEach((width) => {
        doc.rect(currentX, startY, width, rowHeight + headHeight);
        currentX += width;
    });
};