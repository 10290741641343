import React from "react";
import { Modal, Button, ModalHeader, ModalFooter, ModalBody } from "react-bootstrap";

const DiscrepancyModal = ({ show, onClose, onProceed, kitSetErrorFlag }) => {
  console.log("kit error", kitSetErrorFlag);

  return (
    <Modal centered backdrop="static" show={show} onHide={onClose}>
      <ModalHeader>
        <div style={{ display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "20px", flexGrow: 1 }}>
          <i style={{ fontSize: "30px", marginRight: "10px" }} className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
          <span>Warning</span>
        </div>
        <Button 
         variant="light" 
          onClick={onClose} 
          style={{ fontSize: "30px", position: "absolute", top: "0px", right: "10px", border: "none", background: "transparent" }}
          aria-label="Close"
        >
          &times; 
        </Button>
      </ModalHeader>
      <ModalBody className="text-danger">
        THE KIT YOU BOOKED DOES NOT MATCH WHAT WAS REQUESTED. DO YOU WISH TO SAVE?
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onProceed}>
          Save and Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DiscrepancyModal;
