import React, { createRef, Component } from "react";
import { connect } from "react-redux";
import Navbar from "../Navbar";
import { Redirect, Link } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Select from "react-select";
import { bookingService } from "../../../../services";
import { Input,  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, } from "reactstrap";
import { FaCamera } from "react-icons/fa";
import { authHeader, check419, history } from "../../../../helpers";

const reviewOptions = [
  { value: "satisfactory", label: "Satisfactory" },
  { value: "not_satisfactory", label: "Not Satisfactory" },
  { value: "other", label: "Other" },
  { value: "na", label: "NA" },
];

class ItemUsed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      setList: [],
      id: "",
      selectedReviewOption: [],
      selectedReview: "",
      note: "",
      redirect: "",
      from: "",
      searchProducts: "",
      productList: [],
      error: "",
      selectedProductList: [],
      suggestionsActive: false,
      suggestionIndex: 0,
      page: 1,
      case_not_attended_tick: false,
      check_set: false,
      check_set_note: "",
      image: false,
      deleteImage: false,
      preview: "",
    };

    this.listInnerRef = createRef();

    this.getList = this.getList.bind(this);
    this.setSelectedReviewOption = this.setSelectedReviewOption.bind(this);
    this.setNote = this.setNote.bind(this);
    this.updateProductStatus = this.updateProductStatus.bind(this);
    //this.handleQuantity = this.handleQuantity.bind(this);
    this.submitItemUsed = this.submitItemUsed.bind(this);
    this.handleOnCustomProductSearch =
      this.handleOnCustomProductSearch.bind(this);
    this.handleProductList = this.handleProductList.bind(this);
    this.handleOnProductSelect = this.handleOnProductSelect.bind(this);
    this.handleProductQuantity = this.handleProductQuantity.bind(this);
    this.setCaseNotAttended = this.setCaseNotAttended.bind(this);
    this.handleImage = this.handleImage.bind(this);
  }

  getList(id) {
    bookingService.getSetListForReps(id).then((res) => {
      var selectedReview = [];
      selectedReview.push(
        reviewOptions.find((val) => val.value === res.review)
      );

      if (res.data.length != 0) {
        var data = res.data;

        data.map((item) => {
          item._id = item.productId;
        });

        res.data = data;
      }
      console.log("fafafafa", res.data);
      this.setState({
        selectedProductList: res.data,
        selectedReviewOption: selectedReview,
        selectedReview: res.review,
        note: res.description,
        case_not_attended_tick: res.case_not_attended_tick,
        check_set: res.bookingData[0].check_set,
        image:
          res.imagePath != "" && res.imagePath != undefined
            ? process.env.REACT_APP_APIURL +
              "assets/uploads/kitDocuments/item_used/" +
              res.imagePath
            : "",
        check_set_note: res.bookingData[0].check_set_note,
      });
    });
  }

  handleLotNumber(val, index) {
    var selectedProductList = this.state.selectedProductList;
    const inputValue = val || "";

    selectedProductList[index].lot_number = inputValue;

    this.setState({
      selectedProductList: selectedProductList,
    });
  }

  handleRemoveImage = () => {
    this.setState({
      preview: "",
      image: "",
      deleteImage: true,
      error: "",
      showModal: false,
    });
  };

  toggleModal = () => {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  };

  handleOnCustomProductSearch(e) {
    let searchString = e.target.value;
    console.log("string", searchString);
    this.setState(
      {
        searchProducts: searchString,
        page: 1,
      },
      () => {
        this.getSearch();
      }
    );
  }

  getSearch() {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({
        search: this.state.searchProducts,
        page: this.state.page,
      }),
    };

    fetch(
      process.env.REACT_APP_APIURL + "products/searchProducts",
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 419) {
          check419();
        } else {
          this.handleProductList(response.data);
        }
      });
  }

  handleProductList(productList) {
    productList.map((item) => {
      item.nameOld = item.name;
      item.name =
        item.manufacturers_product_code +
        ", " +
        item.name +
        ", " +
        item.product_brand +
        ", " +
        item.code +
        "";
      item.item_in_used = true;
    });

    if (this.state.page > 1 && productList.length > 0) {
      let oldList = this.state.productList;
      let newProductList = oldList.concat(productList);
      this.setState({
        productList: newProductList,
        suggestionsActive: true,
      });
    } else {
      if (this.state.page == 1 || productList.length > 0) {
        this.setState({
          productList: productList,
          suggestionsActive: true,
        });
      }
    }
  }

  handleOnProductSelect(item) {
    item.name = item.nameOld;
    var id = item._id;
    var newProduct;
    var index;

    if (this.state.selectedProductList.length > 0) {
      newProduct = this.state.selectedProductList.find(
        (product) => product._id === id
      );
      index = this.state.selectedProductList.findIndex(
        (product) => product._id === id
      );
    }

    if (newProduct) {
      newProduct.quantity_of_used_item =
        parseInt(newProduct.quantity_of_used_item) + 1;
      newProduct.lot_number = "";
      console.log("gg", newProduct);
      var list = this.state.selectedProductList;
      list[index] = newProduct;

      this.setState({
        selectedProductList: list,
      });
    } else {
      item.quantity_of_used_item = 1;
      item.lot_number = "";
      this.setState({
        selectedProduct: item,
      });

      this.setState((prevState) => ({
        selectedProductList: [item, ...prevState.selectedProductList],
      }));
    }

    this.setState({
      productList: [],
      searchProducts: "",
      suggestionsActive: false,
    });
  }

  handleProductQuantity(quantity, index) {
    var selectedProductList = this.state.selectedProductList;

    const inputValue = parseInt(quantity) || 0;
    const min = 0;

    if (inputValue < min) {
      selectedProductList[index].quantity_of_used_item = min;
    } else {
      selectedProductList[index].quantity_of_used_item = inputValue.toString();
    }

    //selectedProductList[index].quantity_of_used_item = quantity;

    this.setState({
      selectedProductList: selectedProductList,
    });
  }

  deleteProduct(e, id) {
    e.preventDefault();
    if (this.state.case_not_attended_tick && this.state.from === "o") {
      return true;
    }
    if (this.state.case_not_attended_tick && this.state.from === "c") {
      return true;
    } else {
      const newProductList = this.state.selectedProductList.filter(
        (product) => product._id !== id
      );
      this.setState({ selectedProductList: newProductList });
    }
  }

  setSelectedReviewOption(e) {
    var selectedReview = [];
    selectedReview.push({ value: e.value, label: e.label });

    this.setState({
      selectedReviewOption: selectedReview,
      selectedReview: e.value,
      note: "",
    });
  }

  setNote(val) {
    this.setState({
      note: val,
    });
  }

  setCheckSetNote(val) {
    this.setState({
      check_set_note: val,
    });
  }

  setCaseNotAttended(val) {
    console.log("nnnnnnnn", val);
    this.setState({
      case_not_attended_tick: val,
    });
  }

  setCheckSet(val) {
    console.log("check set", val);
    this.setState({
      check_set: val,
    });
  }

  updateProductStatus(val, index) {
    let selectedProductList = this.state.selectedProductList;
    selectedProductList[index].item_in_used = val;

    this.setState({
      selectedProductList: selectedProductList,
    });
  }

  handleQuantity(val, index) {
    let setList = this.state.setList;
    setList[index].quantity_of_used_item = val;
    this.setState({
      setList: setList,
    });
  }

  submitItemUsed(flag) {
    const {
      selectedProductList,
      note,
      selectedReview,
      id,
      user,
      case_not_attended_tick,
      image,
      check_set,
      check_set_note,
    } = this.state;

    const formData = new FormData();

    const data = selectedProductList.map((item) => ({
      _id: item._id,
      item_in_used: item.item_in_used,
      lot_number: item.lot_number || "",
      quantity_of_used_item: Math.abs(item.quantity_of_used_item),
    }));

    formData.append("data", JSON.stringify(data));
    formData.append("description", note ? note : "");
    formData.append("review", selectedReview);
    formData.append("bookingId", id);
    formData.append("userId", user.data._id);
    formData.append(
      "case_not_attended_tick",
      case_not_attended_tick ? case_not_attended_tick : false
    );
    formData.append("check_set", check_set ? check_set : false);
    formData.append("check_set_note", check_set_note ? check_set_note : "");

    formData.append("email_send", flag);
    formData.append("image", image);
    formData.append("deleted", this.state.deleteImage);

    formData.forEach((value, key) => {
      console.log(key, value);
    });
    bookingService
      .updateItemUsedStatusForReps(formData)
      .then((res) => {
        this.setState({ redirect: res.status });
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  handleImage = (event) => {
    const file = event.target.files[0];

    this.setState({
      image: file,
      preview: URL.createObjectURL(file),
      error:"" 
    });
  };

  onScroll = () => {
    if (this.listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log("Reached bottom");

        let page = Number(this.state.page) + 1;

        this.setState(
          {
            page: page,
          },
          () => {
            this.getSearch();
          }
        );
      }
    }
  };

  componentDidMount() {
    this.setState(
      {
        id: this.props.match.params.id,
        from: this.props.match.params.f,
      },
      () => {
        this.getList(this.state.id);
        //this.handleOnProductSearch("", "");
      }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.productsList) {
      this.handleProductList(nextProps.productsList);
    }
  }

  render() {
    // const { redirect } = this.props;

    if (this.state.redirect === 200) {
      return <Redirect to="/reps/open-booking" />;
    }

    if (!this.state.user) {
      return <Redirect to="/" />;
    }

    if (this.state.user.data.user_role !== "reps") {
      return <Redirect to="/" />;
    }
    //const { error } = this.props;
    const isCaseNotAttendedAndNoData =
      this.state.case_not_attended_tick &&
      this.state.selectedProductList.length === 0;

    return (
      <div>
        <Navbar activePage="bookings" />
        <div className="container">
          <h1 className="date-scroll pt-4">
            <a onClick={() => history.goBack()}>
              <i className="fa fa-long-arrow-left smbtn"></i>{" "}
              <strong>Back</strong>
            </a>

            <div className="float-right row">
              <div className="mt-3 mr-3">
                <Input
                  type="checkbox"
                  className="form-check-input mt-1"
                  checked={this.state.case_not_attended_tick}
                  value={this.state.case_not_attended_tick}
                  onChange={(e) => this.setCaseNotAttended(e.target.checked)}
                />
                <h6 className="mb-0 p-0">Case Not Attended</h6>
              </div>
            </div>
          </h1>
          <div className="row pt-4 mobile_nocard">
            <div className="col-lg-12">
              <div className="d-card">
                <div className="form-group">
                  {this.state.from === "o" ? (
                    <label>
                      <strong>Add Item Used</strong>
                    </label>
                  ) : (
                    <label>
                      <strong>Item Used</strong>
                    </label>
                  )}

                  <div className="autocomplete">
                    <div className="wrapper">
                      <div className="sc-hKMtZM">
                        <svg
                          className="sc-bczRLJ jCRvWa search-icon"
                          width="20"
                          height="20"
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                        </svg>
                        <input
                          type="text"
                          placeholder="Search..."
                          className="form-control"
                          value={this.state.searchProducts}
                          onChange={(e) => this.handleOnCustomProductSearch(e)}
                          disabled={
                            this.state.case_not_attended_tick ? true : false
                          }
                        />

                        {this.state.suggestionsActive &&
                          this.state.searchProducts != "" && (
                            <ul
                              className="suggestions"
                              onScroll={() => this.onScroll()}
                              ref={this.listInnerRef}
                            >
                              {this.state.productList.map((item, index) => (
                                <li
                                  className={
                                    index === this.state.suggestionIndex
                                      ? "active"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() =>
                                    this.handleOnProductSelect(item)
                                  }
                                >
                                  <i className="fa fa-search suggestionsSearch"></i>
                                  <span className="suggestionsText ">
                                    {item.name}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                      </div>
                      {this.state.error ? (
                        <span className="text-danger mt-2">
                          {this.state.error?.common}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div id="" className="table-responsive">
                    <table className="table mt-1">
                      <thead>
                        <tr>
                          <th>Product Code</th>
                          <th>Product Description</th>
                          <th>Brand</th>
                          <th>Quantity</th>
                          <th>Lot number</th>
                          <th>Used/Implanted?</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selectedProductList.length > 0 &&
                          this.state.selectedProductList.map(
                            (product, index) => (
                              <tr key={index}>
                                <td>{product?.manufacturers_product_code}</td>
                                <td>{product.name}</td>
                                <td>{product.product_brand}</td>
                                <td className="align-items-start">
                                  {this.state.from === "o" ? (
                                    <input
                                      type="number"
                                      className="form-control sml-input"
                                      value={product.quantity_of_used_item}
                                      min="0"
                                      onChange={(e) =>
                                        this.handleProductQuantity(
                                          e.target.value,
                                          index
                                        )
                                      }
                                      disabled={
                                        this.state.case_not_attended_tick
                                          ? true
                                          : false
                                      }
                                    />
                                  ) : (
                                    <input
                                      type="number"
                                      className="form-control sml-input"
                                      value={product.quantity_of_used_item}
                                      min="0"
                                      onChange={(e) =>
                                        this.handleProductQuantity(
                                          e.target.value,
                                          index
                                        )
                                      }
                                      disabled={
                                        this.state.case_not_attended_tick
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                </td>
                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "start",
                                    marginTop: "-1px",
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="form-control sml-input"
                                    value={product.lot_number}
                                    min="0"
                                    onChange={(e) =>
                                      this.handleLotNumber(
                                        e.target.value,
                                        index
                                      )
                                    }
                                    disabled={
                                      this.state.case_not_attended_tick
                                        ? true
                                        : false
                                    }
                                    style={{ marginTop: "5px" }}
                                  />
                                </td>
                                <td>
                                  {this.state.from === "o" ? (
                                    <div
                                      style={{
                                        pointerEvents: `${
                                          this.state.case_not_attended_tick
                                            ? "none"
                                            : "inherit"
                                        }`,
                                      }}
                                    >
                                      <BootstrapSwitchButton
                                        checked={
                                          product.item_in_used == true
                                            ? true
                                            : false
                                        }
                                        onstyle="primary"
                                        size="sm"
                                        width={70}
                                        onlabel="Yes"
                                        offlabel="No"
                                        onChange={(val) =>
                                          this.updateProductStatus(val, index)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        pointerEvents: `${
                                          this.state.case_not_attended_tick
                                            ? "none"
                                            : "inherit"
                                        }`,
                                      }}
                                    >
                                      <BootstrapSwitchButton
                                        checked={
                                          product.item_in_used == true
                                            ? true
                                            : false
                                        }
                                        onstyle="primary"
                                        size="sm"
                                        width={70}
                                        onlabel="Yes"
                                        offlabel="No"
                                        onChange={(val) =>
                                          this.updateProductStatus(val, index)
                                        }
                                      />
                                    </div>
                                  )}
                                </td>

                                <td>
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      this.deleteProduct(e, product._id)
                                    }
                                  >
                                    <i className="fa fa-trash"></i>{" "}
                                  </a>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-4 mobile_nocard">
            <div className="col-lg-12">
              <div className="d-card">
                <h4 className="mb-2">
                  Outcome of the case <span className="text-danger">*</span>
                </h4>
                <div className="form-group">
                  <Select
                    value={this.state.selectedReviewOption}
                    onChange={this.setSelectedReviewOption}
                    options={reviewOptions}
                    isDisabled={
                      this.state.case_not_attended_tick ? true : false
                    }
                  />
                  {this.state.error ? (
                    <span className="text-danger">
                      {this.state.error?.review}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <h4 className="mb-2">Please specify in notes</h4>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Description"
                    value={this.state.note}
                    onChange={(e) => this.setNote(e.target.value)}
                    disabled={this.state.case_not_attended_tick ? true : false}
                  ></textarea>
                  <div className="form-group">
                    <span className="mr-1">
                      <Input
                        type="checkbox"
                        className="ml-0"
                        checked={this.state.check_set}
                        value={this.state.check_set}
                        disabled={
                          this.state.case_not_attended_tick ? true : false
                        }
                        onChange={(e) => this.setCheckSet(e.target.checked)}
                      />
                      <h6 className="ml-4 mt-3">Check Set on Return</h6>
                    </span>
                    {this.state.check_set && (
                      <div className="">
                        <h4 className="mb-2">
                          Check Set Note <span className="text-danger">*</span>
                        </h4>
                        <textarea
                          id="checkSetNote"
                          className="form-control"
                          rows="3"
                          placeholder="Enter Check Set Note"
                          value={this.state.check_set_note}
                          onChange={(e) => this.setCheckSetNote(e.target.value)}
                          disabled={
                            this.state.case_not_attended_tick ? true : false
                          }
                        ></textarea>
                        {this.state.error ? (
                          <span className="text-danger">
                            {this.state.error?.check_set_note}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
  <div className="col">
    <h4 className="mb-3">Upload Image</h4>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Input
        id="browseImage"
        customLabel="Browse Image"
        name="image"
        type="file"
        accept="image/*"
        onChange={(e) => this.handleImage(e)}
        disabled={this.state.case_not_attended_tick ? true : false}
      />

      <label
        htmlFor="image"
        className="camera-label"
        style={{
          cursor: "pointer",
          marginTop: 10,
        }}
      >
        <FaCamera size={30} /> {/* Display camera icon */}
      </label>
      <Input
        id="image"
        name="image"
        type="file"
        capture="environment"
        accept="image/*"
        onChange={(e) => this.handleImage(e)}
        disabled={this.state.case_not_attended_tick ? true : false}
        style={{ display: "none" }} // Hide the default file input
      />
    </div>

    {(this.state.preview || this.state.image) && (
      <div
        className="mt-3"
        style={{
          position: "relative",
          display: "inline-block",
        }}
      >
        <button
          onClick={this.toggleModal}
          disabled={this.state.case_not_attended_tick ? true : false}
          style={{
            position: "absolute",
            fontSize: 30,
            top: 2,
            right: 2,
            background: "#444fe5",
            color: "white",
            border: "none",
            width: 20,
            height: 20,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            outline: "none",
          }}
        >
          &times;
        </button>

        {this.state.case_not_attended_tick ? (
          <div>
            <img
              src={this.state.preview || this.state.image}
              alt="Preview"
              style={{
                maxWidth: 200,
                maxHeight: 150,
                border: "2px solid #ccc",
              }}
            />
          </div>
        ) : (
          <a
            href={this.state.preview || this.state.image}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={this.state.preview || this.state.image}
              alt="Preview"
              style={{
                maxWidth: 200,
                maxHeight: 150,
                border: "2px solid #ccc",
              }}
            />
          </a>
        )}
      </div>
    )}

    <Modal
      size="md"
      backdrop="static"
      centered
      isOpen={this.state.showModal}
      toggle={() => this.toggleModal(false)}
    >
      <ModalHeader toggle={this.toggleModal}>Delete Image</ModalHeader>
      <ModalBody>
        Are you sure you want to delete this image?
      </ModalBody>
      <ModalFooter>
        <div className="">
          <button
            onClick={this.toggleModal}
            className="btn btn-secondary mr-2"
            data-dismiss="modal"
          >
            No
          </button>
          <button className="btn btn-primary" onClick={this.handleRemoveImage}>
            Yes
          </button>
        </div>
      </ModalFooter>
    </Modal>
  </div>
</div>

                {this.state.error ? (
                  <span className="text-danger">{this.state.error?.image}</span>
                ) : (
                  ""
                )}
              </div>

              <div className="mt-5 text-right">
                <Link
                  to="/reps/open-booking"
                  className="btn btn-secondary mb-3 mr-2"
                >
                  Cancel
                </Link>

                <button
                  className="btn btn-primary mb-3 mr-2"
                  onClick={() => this.submitItemUsed(false)}
                >
                  Save
                </button>

                {!isCaseNotAttendedAndNoData && (
                  <button
                    className="btn btn-primary mb-3"
                    onClick={() => this.submitItemUsed(true)}
                  >
                    Save & Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { productsList } = state.rootReducer.sets;

  return {
    productsList,
  };
}

export default connect(mapStateToProps)(ItemUsed);
